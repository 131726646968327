import { gql } from '@apollo/client';

// 유저 회원가입 (관리자 회원가입이 아님)
export const CREATE_ACCOUNT_MUTATION = gql`
    mutation createAccountMutation($data: SignUpDataDto!) {
        signUpUser(data: $data) {
            mem_idx
            mem_email
            mem_nickname
            mem_lastlogin_datetime
            mem_inactive_check
            mem_level
            mem_point
            mem_kakao_agreement
            mem_sms_agreement
            mem_phone
            extravars {
                mev_key
                mev_value
            }
        }
    }
`;
