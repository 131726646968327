import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import thunk from 'redux-thunk';
import mwPender, { penderReducer } from 'redux-pender'; // 비동기를 위한 라이브러리

// import MWs from "Redux/Middlewares";
import reducers from './reducers';

// 개발 모드일 때만 Redux Devtools 적용
const isDev = process.env.NODE_ENV === 'development';
const devtools = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devtools || compose;

export const Store = createStore(
    combineReducers({
        ...reducers,
        pender: penderReducer,
    }),
    composeEnhancers(
        applyMiddleware(
            thunk,
            mwPender(),
            // MWs.authentication
            // ...add middleware
        ),
    ),
);
export default Store;
