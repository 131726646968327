import { ParsedUrlQuery } from 'querystring';

export function is_true(value: string | number | boolean): boolean {
    if (value === undefined) return false;

    if (value == 'false') return false;
    if (value == 'true') return true;

    const numberedValue = Number(value);
    if (numberedValue === 0) {
        return false;
    } else {
        return true;
    }

    // return value;
}

export function is_trueNumString(value: string | number | boolean): string {
    if (value) {
        return '1';
    }

    return '0';
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: any, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * @param nun string 핸드폰 번호 ('-' 제외)
 * @param hidden boolean 가운데 번호를 ****로 처리 여부
 * @returns string 핸드폰 번호 ('-' 포함)
 */
export function phoneDashFomatter(num: string, hidden = false) {
    let strDashNum = num;
    let pattern = '$1-$2-$3';
    if (hidden) pattern = '$1-****-$3';

    strDashNum = num
        .replace(/[^0-9]/g, '')
        .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, pattern)
        .replace('--', '-');

    return strDashNum;
}

/**
 * 숫자만 남기는 표현식 첫글자는 1~9만 허용
 * 010-2030-4050    ->  1020304050
 * @param strNumber string 핸드폰 번호 ('-' 제외)
 * @returns number 숫자
 */

export function replaceFirstNumbersNotZero(strNumber) {
    const res = strNumber.replace(/[^0-9]/g, '').replace(/(^0+)/, '');
    return Number(res);
}

/**
 * 1234567890  -> 1,234,567,890원
 * @param number 숫자
 * @param unit  단위
 * @returns string
 */
export function numberWithCommas(number: number, unit: string) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit;
}

// export function getPageList(ctx, callback, name) {
//     const query: ParsedUrlQuery = ctx.query;

//     const post_count = 10; // 게시물 수
//     const list_count = 10; // 페이지 수

//     let page: number =
//         isNaN(Number(query?.page)) == true ? 1 : Number(query?.page);

//     const skip = (page - 1) * post_count;
//     const take = post_count * 1;
//     // const brdkey = query.brdkey;
//     // const brdname = query.brdname;
//     const sfield = query.sfield;
//     const skeyword = query.skeyword;
//     const orderby = query.orderby;

//     const data = await exeQuery(callback, ctx, {
//         skip,
//         take,
//         sfield,
//         skeyword,
//         orderby,
//     });

//     const nowPage = page;

//     if (page % list_count != 0) {
//         page = Math.floor(page / list_count);
//         page = page * list_count;
//     } else if (page % list_count == 0) {
//         page = page - (list_count - 1);
//     }

//     // 이부분은 수정이 필요
//     let count = 0;
//     if (data.data.data[name].count == undefined) {
//         count = Number(data.data.data[name].post.count);
//     } else {
//         count = Number(data.data.data[name].count);
//     }
//     const totalPage = Math.ceil(count / post_count);

//     const prevPage = page - list_count < 1 ? 1 : page - list_count;
//     const nextPage =
//         page + list_count > totalPage ? totalPage : page + list_count;

//     return {
//         data,
//         page: {
//             page,
//             nowPage,
//             totalPage,
//             prevPage,
//             nextPage,
//             list_count,
//             // brdkey,
//         },
//     };
// }
