import cx from 'classnames';
import * as _ from 'lodash';
import * as $ from 'jquery';

import { client } from '@/libraries/apollo';
import { GETCONFIG_QUERY } from '@/querys/cmsConfig/getConfig';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logo_white } from '../../../include/Images';

const MenuButton = ({ params }: Record<string, any>) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const active = window.location.pathname.indexOf(params.path);
    return (
        <li className={cx('nav-item')} onClick={() => setIsOpen(!isOpen)}>
            <Link to={params.path} className={cx('nav-link', active >= 0 && 'active')}>
                <i className={cx('nav-icon', params.headerIcon)}></i>
                <p>
                    {params.name}
                    <i className={cx('nav-icon right', params.footerIcon)}></i>
                </p>
            </Link>
            {params.submenus && (
                <ul className="nav nav-treeview">
                    {_.map(params.submenus, (submenu, i) => (
                        <MenuButton key={'sub_' + i} active={false} params={submenu} />
                    ))}
                </ul>
            )}
        </li>
    );
};

const NavBar = ({ menus }: any) => {
    useEffect(() => {
        $('.nav').Treeview('init');
    }, []);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* <!-- Brand Logo --> */}
            <div className="brand-link" onClick={() => window.open('https://www.rs-team.com/', '_blank')}>
                <img src={logo_white} alt="RS-Team Logo" className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light">RS-Team Admin</span>
            </div>

            {/* <!-- Sidebar --> */}
            <div className="sidebar">
                {/* <!-- Sidebar user panel (optional) --> */}
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={logo_white}
                            className="img-circle elevation-2"
                            alt="User Image"
                        />
                    </div>
                </div> */}

                {/* <!-- Sidebar Menu --> */}
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="true"
                    >
                        {/* <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library --> */}
                        {/* {_menus(menus)} */}
                        {_.map(menus, (el, i) => (
                            <MenuButton key={'menu_' + i} params={el} active={false} />
                        ))}
                    </ul>
                </nav>
                {/* <!-- /.sidebar-menu --> */}
            </div>
            {/* <!-- /.sidebar --> */}
        </aside>
    );
};

export default NavBar;
