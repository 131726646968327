import { default_q_mark } from '../../../../include/Images';
import SearchBox from '@/components/commons/SearchBox';
import ControlBox from '@/components/commons/ControlBox';
import useList from '@/hooks/useList.hook';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import _nl2br from 'locutus/php/strings/nl2br';

import { GETTABLIST_QUERY } from '@/querys/admin/tab/getTabList';
import { ADDTAB_MUTATION } from '@/querys/admin/tab/addTab';
import { ADDTABCONTENTS_MUTATION } from '@/querys/admin/tab/addTabContents';
import { DELETETAB_MUTATION } from '@/querys/admin/tab/deleteTab';
import { DELETETABCONTENTS_MUTATION } from '@/querys/admin/tab/deleteTabContents';
import { SETTAB_MUTATION } from '@/querys/admin/tab/setTab';
import { SETTABCONTENTS_MUTATION } from '@/querys/admin/tab/setTabContents';

/**
 * QnA 수정 폼 *********************************************************************
 **/
const Item = ({
    item,
    setQnA = (data, name, contents) => console.log(data, name, contents),
    delQnA = (data) => console.log(data),
}) => {
    const qnaQuestionInput = useRef(null);
    const qnaAnswerInput = useRef(null);

    return (
        <div className="post">
            <div className="user-block">
                <img src={default_q_mark} className="img-circle img-bordered-sm" />
                <span className="username">
                    <div className="input-group">
                        <input
                            ref={qnaQuestionInput}
                            className="form-control"
                            type="text"
                            defaultValue={item.at_tc_name}
                        />
                        <div className="input-group-append">
                            <button
                                type="button"
                                className="btn btn-default"
                                title="QnA 저장"
                                onClick={() => {
                                    if (!window.confirm('저장하시겠습니까?')) return;

                                    setQnA(
                                        item.at_tc_idx,
                                        qnaQuestionInput.current.value,
                                        qnaAnswerInput.current.value,
                                    );
                                }}
                            >
                                <i className="fas fa-save" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                title="QnA 삭제"
                                onClick={() => {
                                    if (!window.confirm('해당 QnA 를 삭제하시겠습니까?')) return;
                                    delQnA(item.at_tc_idx);
                                }}
                            >
                                <i className="fas fa-times" />
                            </button>
                        </div>
                    </div>
                </span>
            </div>

            <textarea
                ref={qnaAnswerInput}
                className="form-control form-control-sm"
                placeholder="Type a comment"
                defaultValue={item.at_tc_contents}
            />
        </div>
    );
};

/**
 * QnA 그룹 폼 *********************************************************************
 **/
const Collapse = ({
    item,
    setTab = (data, name) => console.log(data, name),
    delTab = (data) => console.log(data),
    setQnA = (data, name, contents) => console.log(data, name, contents),
    addQnA = (data) => console.log(data),
    delQnA = (data) => console.log(data),
}) => {
    const [open, setOpen] = useState(false);
    const tabNameInput = useRef(null);

    return (
        <div className="card card-purple card-outline collapsed-card m-2 mb-3">
            {/*style={{ backgroundColor: '#655ba8' }}*/}
            <div className="card-header">
                <h3 className="card-title col-11">
                    <div className="input-group">
                        <input
                            ref={tabNameInput}
                            className={'form-control'}
                            type="text"
                            defaultValue={item.at_tab_name}
                            placeholder="QnA 분류 이름"
                        />
                        <div className="input-group-append">
                            {/* Save Tab Name */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="Tab 이름 저장"
                                onClick={async () => {
                                    setTab(item.at_tab_idx, tabNameInput.current.value);
                                }}
                            >
                                <i className={'fas fa-save'} />
                            </button>
                            {/* Add Item */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="QnA 추가"
                                onClick={async () => {
                                    if (!window.confirm('새로운 QnA 그룹을 생성 하시겠습니까?')) return;
                                    addQnA(item.at_tab_idx);
                                }}
                            >
                                <i className={'fas fa-plus'} />
                            </button>
                            {/* Delete */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="삭제"
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            '해당 QnA 그룹을 전부 삭제하시겠습니까?\nQnA 카테고리에 포함된 모든 QnA가 모두 삭제됩니다.',
                                        )
                                    )
                                        return;
                                    delTab(item.at_tab_idx);
                                }}
                            >
                                <i className="fas fa-trash" />
                            </button>
                        </div>
                    </div>
                </h3>
                <div className="card-tools">
                    {/* Collapse */}
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                        onClick={() => setOpen(!open)}
                    >
                        <i className={open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />
                    </button>
                </div>
            </div>
            <div className="card-body p-3">
                {_.map(item.at_tab_contents, (qna) => (
                    <Item key={'qna_' + qna.at_tc_idx} item={qna} setQnA={setQnA} delQnA={delQnA} />
                ))}
            </div>
        </div>
    );
};

/**
 * QnA 게시판  *********************************************************************
 **/
const QnA: React.FC = (): JSX.Element => {
    const [
        state,
        {
            getCheckItems,
            setSearch,
            onChoice,
            onResetChoice,
            onChecked,
            onCheckedAll,
            onReset,
            onUpdate,
            onDelete,
            onAdd,
        },
        { pages, movePage },
    ] = useList({});

    /**
     * 목록 가져오기 쿼리
     */
    // get Tab/Contents
    const [getTabList, { data }] = useLazyQuery(GETTABLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { list = [] } = data?.getTabList;
            const count = list.length;
            onReset(list, count);
        },
        onError: (err) => console.error(err),
    });

    // add Items
    const [addTabItem] = useMutation(ADDTAB_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [addContentsItem] = useMutation(ADDTABCONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    // set Tab/Contents Items
    const [setTabItem] = useMutation(SETTAB_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [setContentsItem] = useMutation(SETTABCONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    // delete Items
    const [delTabItem] = useMutation(DELETETAB_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [delContentsItem] = useMutation(DELETETABCONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    const graphql_data = {
        variables: {
            where: {
                at_tab_page: 'qna',
            },
        },
    };

    /**
     * 초기 1회 실행
     */
    useEffect(() => {
        getTabList(graphql_data);
    }, [state.search]);

    // 검색 기능 => "Tab Name(제목)"
    const handleSearch = async (search) => {
        setSearch(search);
        if (search.word.length > 0) {
            graphql_data.variables.where['at_tab_name'] = search.word;
        } else {
            delete graphql_data.variables.where['at_tab_name'];
        }
        await getTabList(graphql_data);
    };

    // 껍데기 부분
    return (
        <div className="invoice m-0 p-0">
            <div className="card-header">
                <h3 className="card-title">Athenes QnA</h3>
                {/* <div className="card-tools">
                    <SearchBox
                        inSearch={false}
                        onSearch={handleSearch}
                        placeholder="제목"
                    />
                </div> */}
            </div>

            <div className="card-body p-0">
                <ControlBox
                    onRefresh={() => {
                        getTabList(graphql_data);
                    }}
                    onAdd={async () => {
                        let res = await addTabItem({
                            variables: {
                                data: {
                                    at_tab_name: 'TAB NAME',
                                    at_tab_page: 'qna',
                                },
                            },
                        });

                        if (res?.errors) {
                            alert('QnA 그룹 생성에 실패했습니다.');
                            return;
                        }

                        res = await addContentsItem({
                            variables: {
                                data: {
                                    at_tab_idx: res.data.addTab.at_tab_idx,
                                    at_tc_name: 'Question',
                                    at_tc_contents: 'Answer',
                                    at_tc_url: '',
                                    at_tc_img: '1',
                                    at_tc_type: 'qna',
                                    at_tc_sub_type: '',
                                },
                            },
                        });

                        if (res?.errors) {
                            alert('QnA 생성에 실패했습니다.');
                            return;
                        }

                        getTabList(graphql_data);
                    }}
                />
                {_.map(state.view_list, (el, i) => {
                    return (
                        <Collapse
                            key={'cqna_' + i}
                            item={el.data}
                            setTab={async (tab_idx, tab_name) => {
                                const res = await setTabItem({
                                    variables: {
                                        data: {
                                            at_tab_name: tab_name,
                                        },
                                        where: {
                                            at_tab_idx: tab_idx,
                                        },
                                    },
                                });
                                if (res?.errors) {
                                    alert('이름 수정에 실패했습니다.');
                                    return;
                                }
                                if (res?.data) {
                                    alert('이름을 수정했습니다.');
                                    return;
                                }
                            }}
                            delTab={async (tab_idx) => {
                                const res = await delTabItem({
                                    variables: {
                                        where: {
                                            at_tab_idx: tab_idx, //tabObj.at_tab_idx,
                                        },
                                    },
                                });
                                if (res?.errors) {
                                    alert('삭제에 실패했습니다.');
                                    return;
                                }
                                onDelete(null, el.id);
                                //getTabList(graphql_data);
                            }}
                            addQnA={async (tab_idx) => {
                                const res = await addContentsItem({
                                    variables: {
                                        data: {
                                            at_tab_idx: tab_idx,
                                            at_tc_name: 'Question',
                                            at_tc_contents: 'Answer',
                                            at_tc_url: '',
                                            at_tc_img: '1',
                                            at_tc_type: 'qna',
                                            at_tc_sub_type: '',
                                        },
                                    },
                                });
                                if (res?.errors) {
                                    alert('추가에 실패했습니다.');
                                    return;
                                }
                                getTabList(graphql_data);
                            }}
                            setQnA={async (tc_idx, tc_name, tc_contents) => {
                                const res = await setContentsItem({
                                    variables: {
                                        data: {
                                            at_tc_name: tc_name,
                                            at_tc_contents: tc_contents,
                                        },
                                        where: {
                                            at_tc_idx: tc_idx,
                                        },
                                    },
                                });
                                if (res?.errors) {
                                    alert('QnA 수정에 실패했습니다.');
                                    return;
                                }
                                if (res?.data) {
                                    alert('QnA가 수정되었습니다.');
                                    return;
                                }
                            }}
                            delQnA={async (tc_idx) => {
                                const res = await delContentsItem({
                                    variables: {
                                        where: {
                                            at_tc_idx: tc_idx,
                                        },
                                    },
                                });
                                if (res?.errors) {
                                    alert('삭제에 실패했습니다.');
                                    return;
                                }
                                getTabList(graphql_data);
                            }}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default QnA;
