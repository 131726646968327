import _ from 'lodash';
import React, { Component } from 'react';
import { FileDrop } from 'react-file-drop';

import FileService from './FileService';
import ItemCard from './ItemCard';
import ItemCard_Small from './ItemCardSmall';

declare interface FileData {
    uid: number;
    file: Record<string, any> | null;
    resData: Record<string, any> | null;
    error: Record<string, any> | null;
    perProgress: number;
    isUploading: boolean;
    isFinish: boolean;
}

declare interface OPTION {
    isEnabled: boolean;
    isDragNDrop: boolean;
    type: string;
    subtype: string;
    maxFileNum: number;
    maxDropNum: number;
    totalFileSize: number;
    maximumFileSize: number;
}

enum CHECKNUM {
    SUCCESS = 0,
    DISABLED,
    NO_DND,
    MAX_DROP_NUM,
    MAX_FILE_NUM,
    ZERO_FILE_SIZE,
    MAX_FILE_SIZE,
    MAX_TOTAL_SIZE,
}

class MultiFileUpload extends Component<
    { uid: string | number; list: any[]; options: OPTION; onFinally: (res: Record<string, FileData>) => void },
    { options: OPTION; fileList: Record<string, FileData> }
> {
    initFileData: FileData = {
        uid: 0,
        file: null,
        resData: null,
        error: null,
        perProgress: 0,
        isUploading: false,
        isFinish: false,
    };

    initOptions: OPTION = {
        isEnabled: true,
        isDragNDrop: false, //드래그앤드롭 업로드 기능 사용여부
        type: 'board', // 게시판 타입
        subtype: 'board1', // 게시판 서브타입
        maxFileNum: 10, // 업로드 가능한 파일 최대 갯수 (업로드된 파일 + 추가되는 파일)
        maxDropNum: 10, // 드래그앤드롭 1회에 허용할 파일 갯수 (0이면 maxFileNum을 따라감)
        totalFileSize: 200 * 1024, //업로드 가능한 파일 최대 사이즈 (업로드된 파일 + 추가되는 파일)
        maximumFileSize: 200 * 1024, //한개의 파일 당 업로드 파일 사이즈
    };

    constructor(props) {
        super(props);
        Object.freeze(this.initFileData);
        Object.freeze(this.initOptions);

        props = {
            list: [],
            options: this.props.options ? this.props.options : this.initOptions,
            onFinally: (files) => {
                console.log(files);
            },
        };

        this.state = {
            options: this.props.options,
            fileList: {},
        };
    }

    checkOptions = (files, isOnDrag): number => {
        const { options, fileList } = this.state;
        let { isEnabled, maxFileNum, maxDropNum, totalFileSize, maximumFileSize } = options;

        if (!isEnabled) return CHECKNUM.DISABLED;

        //드래그앤드롭 사용여부
        if (isOnDrag && !options.isDragNDrop) return CHECKNUM.NO_DND;

        //0이면 maxFileNum을 따라감
        maxDropNum = maxDropNum === 0 ? maxFileNum : maxDropNum;
        //0이면 totalFileSize을 따라감
        maximumFileSize = maximumFileSize === 0 ? totalFileSize : maximumFileSize;

        //드랍된 파일 갯수가 설정치보다 높을때 false
        if (files.length > maxDropNum) return CHECKNUM.MAX_DROP_NUM;
        //드랍된 파일 + 업로드파일 갯수가 설정치보다 높을때 false
        if (files.length + _.size(fileList) > maxFileNum) return CHECKNUM.MAX_FILE_NUM;

        let filesSize = 0;
        //이미 업로드된 파일 사이즈가 totalFileSize가 높을 때 false
        _.forEach(fileList, (el, i) => {
            filesSize += el?.resData?.fil_size;
        });
        if (filesSize > totalFileSize) return CHECKNUM.MAX_TOTAL_SIZE;

        for (let i = 0; i < files.length; ++i) {
            //드랍된 파일 사이즈가 0이거나 설정치보다 높으면 False
            if (files[i].size <= 0) return CHECKNUM.ZERO_FILE_SIZE;
            if (files[i].size > maximumFileSize) return CHECKNUM.MAX_FILE_SIZE;
            filesSize += files[i].size;
            if (filesSize > totalFileSize) return CHECKNUM.MAX_TOTAL_SIZE;
        }

        return CHECKNUM.SUCCESS;
    };

    componentDidMount() {
        const { list = [] } = this.props;

        // let _fileList: Record<string, FileData> = {};
        // const _uid = Date.now();

        // for (let i = 0; i < list.length; ++i) {
        //     const _fileData: FileData = {
        //         ...this.initFileData,
        //         uid: _uid + i,
        //         resData: { ...list[i], fil_idx: [list[i].fil_idx] },
        //         isUploading: false,
        //         isFinish: true,
        //     };

        //     _fileList = { ..._fileList, [_fileData.uid]: _fileData };
        // }
        // this.setState({
        //     ...this.state,
        //     fileList: { ...this.state.fileList, ..._fileList },
        // });
    }

    /**
     * File Uploade
     */
    onDrop = async (dropFiles, e, isOnDrag = true) => {
        //console.log(dropFiles);

        e.preventDefault();

        const isCheck = this.checkOptions(dropFiles, isOnDrag);
        if (isCheck) {
            console.log('파일 옵션 제한 :', isCheck);
            return;
        }

        let _fileList: Record<string, FileData> = {};
        const dropFileLength = dropFiles.length;
        //고유 아이디 부여
        const _uid = Date.now();
        for (let i = 0; i < dropFileLength; ++i) {
            const _fileData: FileData = {
                ...this.initFileData,
                uid: _uid + i,
                file: dropFiles[i],
                isUploading: true,
            };

            _fileList = { ..._fileList, [_fileData.uid]: _fileData };
        }
        await this.setState({
            ...this.state,
            fileList: { ...this.state.fileList, ..._fileList },
        });

        /**
         * 파일 개별 업로드
         */
        _.forEach(_fileList, (el, key) => {
            const callback = {
                onUploadProgress: (e) => {
                    //    el.isUploading = true;
                    el.perProgress = Number(Math.round((e.loaded * 100) / e.total));
                    this.setState({
                        ...this.state,
                        fileList: { ...this.state.fileList, ..._fileList },
                    });
                },
                onComplete: (res) => {
                    switch (res.status) {
                        case 200:
                        case 201:
                            if (res.data?.data?.length > 0) {
                                el.resData = res.data.data[0];
                            }
                            break;
                        default:
                            el.error = res?.data;
                    }
                },
                onError: (err) => {
                    el.error = err;
                },
                onFinally: () => {
                    el.isUploading = false;
                    el.isFinish = true;
                    this.setState({
                        ...this.state,
                        fileList: { ...this.state.fileList, ..._fileList },
                    });
                    this.props.onFinally(this.state.fileList);
                },
            };

            /**
             * 업로드 시도
             */
            FileService.upload(this.state.options.type, this.state.options.subtype, el, callback, true);

            /**
             * 업로드 이후 file 업로드 결과를 콜백함수로 리턴
             */
            this.props.onFinally(this.state.fileList);
        });
    };

    onChange = async (e) => {
        this.onDrop(e.target.files, e, false);
        e.target.value = null;
    };

    onRemove = async (e, uid) => {
        e.preventDefault();
        const _fileList = this.state.fileList;
        const fil_idx = _fileList[uid].resData.fil_idx[0];
        const callback: any = {
            onComplete: (res: any) => {
                _fileList[uid] = undefined;
                delete _fileList[uid];

                this.setState({
                    ...this.state,
                    fileList: { ..._fileList },
                });
            },
            onError: (err) => {
                console.log('삭제 실패 : ' + uid, err);
            },
            onFinally: () => {},
        };
        await FileService.delete(fil_idx, callback);
    };

    onDownload = (e, uid) => {
        e.preventDefault();
        FileService.downlaod(this.state.fileList[uid].resData.fil_filename);
    };

    render() {
        const { fileList, options } = this.state;

        if (!options.isDragNDrop && options.isEnabled) {
            return (
                <>
                    <div className="btn-group btn-group-toggle col-12" data-toggle="buttons">
                        <label
                            htmlFor={'file_' + this.props.uid}
                            className="btn btn-app btn-lg btn-default text-center"
                            style={{ width: '100%', height: '100%' }}
                        >
                            {_.size(fileList) <= 0 &&
                            (this.props.list[0] === undefined || this.props.list[0] === null) ? (
                                <>
                                    <i className="fas fa-plus" /> <br />
                                    이미지업로드
                                </>
                            ) : (
                                _.size(fileList) <= 0 && (
                                    <img
                                        style={{ width: '100%', height: '100%' }}
                                        src={
                                            (process.env.NODE_ENV == 'development'
                                                ? process.env.REACT_APP_DEVELOPMENT_BACKEND_HOST
                                                : process.env.REACT_APP_PRODUCTION_BACKEND_HOST) +
                                            '/PDFdownloads/' +
                                            this.props.list[0]
                                        }
                                    />
                                )
                            )}
                            {_.map(fileList, (file, i) => {
                                if (file?.resData?.fil_filename) {
                                    return (
                                        <>
                                            <span
                                                className="badge badge-danger"
                                                onClick={(e) => {
                                                    this.onRemove(e, file.uid);
                                                }}
                                            >
                                                <i className="fas fa-times" />
                                            </span>
                                            <img
                                                style={{ width: '100%', height: '100%' }}
                                                src={
                                                    (process.env.NODE_ENV == 'development'
                                                        ? process.env.REACT_APP_DEVELOPMENT_BACKEND_HOST
                                                        : process.env.REACT_APP_PRODUCTION_BACKEND_HOST) +
                                                    '/PDFdownloads/' +
                                                    file?.resData?.fil_filename
                                                }
                                            />
                                        </>
                                    );
                                }
                            })}
                            <input
                                name="anyfile[]"
                                style={{ display: 'none' }}
                                id={'file_' + this.props.uid}
                                type="file"
                                onChange={this.onChange}
                            />
                        </label>
                    </div>
                </>
            );
        }

        return (
            <div className="container border">
                <div className="row">
                    <div className="col-md-12">
                        <FileDrop
                            className={'file-drop'}
                            targetClassName={options.isDragNDrop ? 'file-drop-target' : ''}
                            draggingOverFrameClassName={options.isDragNDrop ? 'file-drop-dragging-over-frame' : ''}
                            onDrop={this.onDrop}
                        >
                            {_.size(fileList) > 0 ? (
                                <>
                                    {_.map(fileList, (el, i) => (
                                        <ItemCard_Small
                                            key={el.uid}
                                            data={el}
                                            //doFileUpload={true}
                                            onRemove={this.onRemove}
                                            onDownload={this.onDownload}
                                        />
                                    ))}
                                </>
                            ) : (
                                <>이미지를 끌어서 놓으세요</>
                            )}
                        </FileDrop>
                    </div>
                </div>
            </div>
        );
    }
}

export default MultiFileUpload;
