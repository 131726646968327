import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';

import Main from '@/pages/user/main';
import Signin from '@/pages/user/account/signin';
import Signup from '@/pages/user/account/signup';

const Account = () => {
    return (
        <>
            <Switch>
                <Route exact path="/signin" component={Signin} />
                <Route exact path="/signup" component={Signup} />
            </Switch>
        </>
    );
};

export default Account;
