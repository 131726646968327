import React from 'react';
import Text0 from './Text_0';
import Text_Group from './Text_Group';
import Email0 from './Email_0';
import Passwd_Group from './Passwd_Group';
import Textarea0 from './Textarea_0';
import Number0 from './Number_0';
import Checkbox0 from './Checkbox_0';

const renderField: React.FC<any> = (props): JSX.Element => {
    const _type = props.type.toLowerCase();

    return (
        <>
            {_type === 'text' && <Text0 {...props} />}
            {_type === 'textgroup' && <Text_Group {...props} />}
            {_type === 'passwordgroup' && <Passwd_Group {...props} />}
            {_type === 'email' && <Email0 {...props} />}
            {_type === 'textarea' && <Textarea0 {...props} />}
            {_type === 'number' && <Number0 {...props} />}
            {_type === 'checkbox' && <Checkbox0 {...props} />}
        </>
    );
};

export default renderField;

{
    /* <div>
    
    {(_type === "text" || _type === "email") && <Text0 {...props} />}
    {_type === "verify" && <TextVrify {...props} />}
    {_type === "textarea" && <Textarea {...props} />}
    {_type === "select" && <Select {...props} />}
    {_type === "selectTest" && <SelectTest {...props} />}
    {_type === "slider" && <Slider {...props} />}
    {_type === "number" && <Number {...props} />}
    {_type === "checkbox" && <CheckBox {...props} />}
    {props.type === "email" && <Email0 {...props} />}
    {(_type === "password" || _type === "passwd") && (
        <Passwd0 {...props} />
    )}
    체크 박스는 따로 모듈을 사용중
    레디오는 모듈을 사용하든, 하나 만들든 해야 할듯
    URL형식을 하나 추가해야 함

    {_type === "phone" && <PhoneVerify {...props} />}
</div> */
}
