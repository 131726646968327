import { gql } from '@apollo/client';

// Tab 추가
export const DELETECOLLAPSECONTENTS_MUTATION = gql`
    mutation deleteCollapseContentsMutation($where: DeleteCollapseContentsWhereDto!) {
        deleteCollapseContents(where: $where) {
            at_coc_idx
        }
    }
`;
