import { gql } from '@apollo/client';

// 게시물 하나 가져오기
export const GETPOSTONE_QUERY = gql`
    query getPostQuery($option: GetPostOneOptionDto!, $where: GetPostOneWhereDto!) {
        getPostOne(option: $option, where: $where) {
            board {
                brd_idx
                brd_key
                brd_name
                # meta {
                # board_skin
                # list_count
                # mobile_list_count
                # use_category
                # use_inquiry_mode
                # use_post_secret
                # use_comment_secret
                # use_post_like
                # use_post_dislike
                # use_upload_file
                # upload_file_num
                # comment_count
                # mobile_comment_count
                # use_board_point
                # point_read
                # point_write
                # point_comment
                # point_delete_post
                # point_delete_post_admin
                # point_delete_comment
                # point_delete_comment_admin
                # point_upload
                # point_download
                # point_download_uploader
                # point_like_post_writer
                # point_like_post_reader
                # point_dislike_post_writer
                # point_dislike_post_reader
                # point_like_comment_writer
                # point_like_comment_reader
                # point_dislike_comment_writer
                # point_dislike_comment_reader
                # access_list
                # access_view
                # access_write
                # access_comment
                # access_upload
                # access_upload
                #     extravars_json
                # }
                # category {
                #     bca_idx
                #     bca_brd_idx
                #     bca_key
                #     bca_value
                #     bca_parent
                #     bca_order
                # }
            }
            post {
                pst_idx
                pst_brd_idx
                pst_bca_idx
                pst_mem_idx
                pst_mem_email
                pst_mem_nickname
                pst_secret
                pst_blind
                pst_notice
                pst_deletion
                pst_title
                pst_content
                pst_datetime
                pst_updated_datetime
                # pst_deletion_datetime
                pst_hit
                pst_ip
                pst_report
                pst_like
                pst_dislike
                # extravars {
                #     pev_key
                #     pev_value
                # }
                writer {
                    # mem_idx
                    mem_email
                    mem_nickname
                    # mem_level
                    # mem_point
                    # is_member
                }
                files {
                    fil_idx
                    fil_filename
                    fil_originname
                    fil_path
                    fil_size
                    fil_mimetype
                }
                # comment {
                #     count
                #     list {
                #         com_idx
                #         com_pst_idx
                #         com_brd_idx
                #         com_num
                #         com_reply
                #         com_mem_idx
                #         com_mem_email
                #         com_mem_nickname
                #         com_password
                #         com_secret
                #         com_blind
                #         com_content
                #         com_datetime
                #         com_updated_datetime
                #         com_deletion_datetime
                #         com_ip
                #         com_report
                #         com_like
                #         com_dislike
                #         writer {
                #             mem_idx
                #             mem_email
                #             mem_nickname
                #             mem_level
                #             mem_point
                #             is_member
                #         }
                #     }
                # }
                prev_post {
                    post {
                        pst_idx
                        pst_title
                        writer {
                            mem_idx
                            mem_email
                            mem_nickname
                            mem_level
                            mem_point
                            is_member
                        }
                    }
                    # comment {
                    #     count
                    # }
                }
                next_post {
                    post {
                        pst_idx
                        pst_title
                        writer {
                            mem_idx
                            mem_email
                            mem_nickname
                            mem_level
                            mem_point
                            is_member
                        }
                    }
                    # comment {
                    #     count
                    # }
                }
            }
        }
    }
`;
