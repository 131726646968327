import { gql } from '@apollo/client';

// Tab 추가
export const SETCOLLAPSE_MUTATION = gql`
    mutation setCollapseMutation($data: SetCollapseDataDto!, $where: SetCollapseWhereDto!) {
        setCollapse(data: $data, where: $where) {
            at_col_idx
            at_col_name
            at_col_sub_name
        }
    }
`;
