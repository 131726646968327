import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Route, withRouter, Redirect, Switch, Link } from 'react-router-dom';

import Main from '@/pages/admin/main';
import Signin from '@/pages/user/account/signin';
import Signup from '@/pages/user/account/signup';

import Website from '@/pages/admin/website';
import Athenes from '@/pages/admin/athenes';

const Admin = () => (
    <Switch>
        <Route exact path="/" component={Main} />

        <Route
            path={['/website/portfolio', '/website/diary', '/website/notice', '/website/activity', '/website/contact']}
            component={Website}
        />
        <Route
            path={['/athenes/function', '/athenes/experience', '/athenes/qna', '/athenes/contact']}
            component={Athenes}
        />
    </Switch>
);

export default Admin;
