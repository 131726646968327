import { gql } from '@apollo/client';

//로그인
export const LOGIN_MUTATION = gql`
    mutation loginMutation($data: SignInDataDto!) {
        signInUser(data: $data) {
            mem_idx
            mem_email
            mem_nickname
            mem_lastlogin_datetime
            # mem_lastlogin_timestamp
            # mem_inactive_check
            mem_level
            mem_point
            accessToken
            refreshToken
        }
    }
`;
