import { useState } from 'react';

interface SearchWords {
    isInSearch: boolean;
    word: string | null;
}

const SearchBox = (props) => {
    const {
        inSearch = false,
        onSearch = (searchWords) => {
            console.log(searchWords);
        },
        placeholder = 'Search',
    } = props;

    const [searchWords, setSearchWords] = useState<SearchWords>({
        isInSearch: false,
        word: '',
    });

    /**
     * 엔터 입력시  버튼 동작
     * @param e element Event object
     */
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSearch(searchWords);
        }
    };

    return (
        <div className="navbar-search-block" id="navbar-search4">
            <div className="form-inline input-group input-group-sm">
                {inSearch && (
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            {/* 결과 내 검색 체크박스 */}
                            <input
                                type="checkbox"
                                checked={searchWords.isInSearch}
                                onClick={() =>
                                    setSearchWords({
                                        ...searchWords,
                                        isInSearch: !searchWords.isInSearch,
                                    })
                                }
                            />{' '}
                            결과 내 검색
                        </span>
                    </div>
                )}
                <input
                    className="form-control form-control-navbar"
                    type="search"
                    placeholder={placeholder}
                    aria-label="Search"
                    value={searchWords.word}
                    onChange={(e) =>
                        setSearchWords({
                            ...searchWords,
                            word: e.currentTarget.value,
                        })
                    }
                    onKeyPress={handleKeyPress}
                />

                <div className="input-group-append">
                    <button className="btn btn-default btn-navbar" type="button" onClick={() => onSearch(searchWords)}>
                        <i className="fas fa-search"></i>
                    </button>
                    {/* <button
                            className="btn btn-default btn-navbar"
                            type="button"
                            data-widget="navbar-search"
                            onClick={() =>
                                setSearchWords({
                                    ...searchWords,
                                    words: '',
                                })
                            }
                        >
                            <i className="fas fa-times"></i>
                        </button> */}
                </div>
            </div>
        </div>
    );
};

export default SearchBox;
