import Layout from '@/components/admin/_layout';
import Account from '@/routers/account';
import Admin from '@/routers/admin';
import User from '@/routers/user';
import { Redirect } from 'react-router-dom';

import { levelVar, isLoggedInVar, client } from '@/libraries/apollo';
import { useReactiveVar } from '@apollo/client';

export const App = (props) => {
    const isSigned = useReactiveVar(isLoggedInVar);

    return (
        <>
            {!isSigned ? (
                <Account />
            ) : (
                <Layout>
                    <Admin />
                </Layout>
            )}

            {!isSigned ? (
                location.pathname !== '/signup' ? (
                    <Redirect to="/signin" />
                ) : (
                    <Redirect to="/signup" />
                )
            ) : location.pathname === '/' ? (
                <Redirect to="/" />
            ) : (
                <Redirect to={location.pathname} />
            )}
        </>
    );
};
