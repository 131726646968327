import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import { GETCONFIG_QUERY } from '@/querys/cmsConfig/getConfig';

import { Tab, TabSections } from '@/components/commons/Tab';

import FunctionList from './contents/function_list';
import Experience from './contents/experience';
import QnA from './contents/qna';
import QnA_Dongmin from './contents/qna_dongmin';
import Contact from './contents/contact';

const Athenes: React.FC = (): JSX.Element => {
    const asPath = window.location.pathname;
    const asPathArr = asPath.split('/');
    const type = asPathArr[asPathArr.length - 1];

    return (
        <>
            <Helmet>
                <title>RS | {type}</title>
            </Helmet>

            <TabSections
                options={{
                    type: 'config',
                    title: 'Athenes 관리 페이지',
                    path: `${asPath}`,
                    pathname: '/athenes',
                    header: '',
                }}
            >
                <Tab name="Function" type="function" active={type}>
                    <FunctionList />
                </Tab>
                <Tab name="Experience" type="experience" active={type}>
                    <Experience />
                </Tab>
                <Tab name="QnA" type="qna" active={type}>
                    <QnA />
                </Tab>
                <Tab name="Contact" type="contact" active={type}>
                    <Contact />
                </Tab>

                {/* <Tab name="API정보" type="apiinfo" active={type}>
                            <ApiInfo
                                onSubmit={handleSubmit}
                                loading={false}
                                data={data.getConfig}
                            />
                        </Tab> */}
            </TabSections>
        </>
    );
};
// };

export default Athenes;
