import { gql } from '@apollo/client';
export const ADDPOST_MUTATION = gql`
    mutation addPostMutation($where: AddPostWhereDto!, $data: AddPostDataDto!, $option: AddPostOptionDto!) {
        addPost(where: $where, data: $data, option: $option) {
            post {
                pst_idx
                pst_brd_idx
                pst_bca_idx
                pst_mem_idx
                pst_mem_email
                pst_mem_nickname
                pst_secret
                pst_blind
                pst_notice
                pst_deletion
                pst_title
                pst_content
                pst_thumbnail
                pst_datetime
                pst_updated_datetime
                pst_deletion_datetime
                pst_hit
                pst_ip
                pst_report
                pst_like
                pst_dislike
                extravars {
                    pev_key
                    pev_value
                }
                writer {
                    is_member
                    mem_email
                    mem_nickname
                    mem_level
                    mem_point
                    mem_group {
                        mgr_idx
                        mgr_title
                        mgr_datetime
                    }
                }
                files {
                    fil_idx
                    fil_originname
                    fil_path
                    fil_size
                }
            }
            board {
                brd_idx
                brd_key
                brd_name
                meta {
                    board_skin
                    list_count
                    mobile_list_count
                    use_category
                    use_inquiry_mode
                    use_post_secret
                    use_comment_secret
                    use_post_like
                    use_post_dislike
                    use_upload_file
                    upload_file_num
                    comment_count
                    mobile_comment_count
                    use_board_point
                    point_read
                    point_write
                    point_comment
                    point_delete_post
                    point_delete_post_admin
                    point_delete_comment
                    point_delete_comment_admin
                    point_upload
                    point_download
                    point_download_uploader
                    point_like_post_writer
                    point_like_post_reader
                    point_dislike_post_writer
                    point_dislike_post_reader
                    point_like_comment_writer
                    point_like_comment_reader
                    point_dislike_comment_writer
                    point_dislike_comment_reader
                    access_list
                    access_view
                    access_write
                    access_comment
                    access_upload
                    extravars_json
                }
            }
        }
    }
`;
