//파일 하나 삭제하기
import { gql } from '@apollo/client';

export const DELETE_FILE_ONE_MUTATION = gql`
    mutation deleteFileOneMutation($where: DeleteFileOneDto!) {
        deleteFileOne(where: $where) {
            fil_idx
        }
    }
`;
