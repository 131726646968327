import { default_q_mark } from '../../../../include/Images';

import FS from '@/components/commons/FileUploader/FileService';

import MultiFileUpload from '@/components/commons/FileUploader/MultiFileUpload';
import ControlBox from '@/components/commons/ControlBox';
import useList from '@/hooks/useList.hook';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';

import { GETTABLIST_QUERY } from '@/querys/admin/tab/getTabList';
import { ADDTAB_MUTATION } from '@/querys/admin/tab/addTab';
import { ADDTABCONTENTS_MUTATION } from '@/querys/admin/tab/addTabContents';
import { DELETETAB_MUTATION } from '@/querys/admin/tab/deleteTab';
import { DELETETABCONTENTS_MUTATION } from '@/querys/admin/tab/deleteTabContents';
import { SETTAB_MUTATION } from '@/querys/admin/tab/setTab';
import { SETTABCONTENTS_MUTATION } from '@/querys/admin/tab/setTabContents';

/**
 * Experience 수정 폼 *********************************************************************
 **/
const Item = ({
    item,
    setExperience = (data, name, contents, url, img) => console.log(data, name, contents, url, img),
    delExperience = (data) => console.log(data),
}) => {
    const expNameInput = useRef(null);
    const expDescriptionInput = useRef(null);
    const expURLInput = useRef(null);

    const [imgURL, setImgURL] = useState(null);

    return (
        <div className="post" key={item.at_tc_idx}>
            <div className="user-block">
                {/* <img src={default_q_mark} className="img-circle img-bordered-sm" /> */}

                <div className="input-group">
                    {/* Project Name */}
                    <input ref={expNameInput} className="form-control" type="text" defaultValue={item.at_tc_name} />
                    <div className="input-group-append">
                        {/* Save Button */}
                        <button
                            type="button"
                            className="btn btn-default"
                            title="Project 저장"
                            onClick={() => {
                                if (!window.confirm('저장하시겠습니까?')) return;
                                setExperience(
                                    item.at_tc_idx,
                                    expNameInput.current.value,
                                    expDescriptionInput.current.value,
                                    expURLInput.current.value,
                                    imgURL,
                                );
                            }}
                        >
                            <i className="fas fa-save" />
                        </button>
                        {/* Delete Button */}
                        <button
                            type="button"
                            className="btn btn-default"
                            title="Project 삭제"
                            onClick={() => {
                                if (!window.confirm('해당 Project 를 삭제하시겠습니까?')) return;
                                delExperience(item.at_tc_idx);
                            }}
                        >
                            <i className="fas fa-times" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-10">
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fas fa-location-arrow" />
                            </span>
                        </div>
                        {/* Project URL */}
                        <input
                            ref={expURLInput}
                            type="text"
                            className="form-control"
                            placeholder="URL"
                            defaultValue={item.at_tc_url}
                        />
                    </div>
                    {/* Project Description */}
                    <textarea
                        ref={expDescriptionInput}
                        className="form-control form-control-sm"
                        placeholder="Type a comment"
                        defaultValue={item.at_tc_contents}
                    />
                </div>
                <div className="col-2 ml-1 row">
                    {/* Project Main Image */}
                    <MultiFileUpload
                        uid={item.at_tc_idx}
                        options={{
                            isEnabled: true,
                            isDragNDrop: false,
                            type: 'board',
                            subtype: 'board1',
                            maxFileNum: 1,
                            maxDropNum: 1,
                            totalFileSize: 10 * 1024 * 1024,
                            maximumFileSize: 10 * 1024 * 1024,
                        }}
                        list={[item.at_tc_img]}
                        onFinally={(data) => {
                            _.map(data, (file) => {
                                if (file?.resData?.fil_filename) {
                                    setImgURL(file.resData.fil_filename);
                                }
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

/**
 * Experience 그룹 폼 *********************************************************************
 **/
const Collapse = ({
    item,
    setTab = (data, name) => console.log(data, name),
    delTab = (data) => console.log(data),
    setExperience = (data, name, contents, url, img) => console.log(data, name, contents, url, img),
    addExperience = (data) => console.log(data),
    delExperience = (data) => console.log(data),
}) => {
    const [open, setOpen] = useState(false);
    const tabNameInput = useRef(null);

    return (
        <div className="card card-purple card-outline collapsed-card m-2 mb-3">
            <div className="card-header">
                <h3 className="card-title col-11">
                    <div className="input-group">
                        <input
                            ref={tabNameInput}
                            className="form-control"
                            type="text"
                            defaultValue={item.at_tab_name}
                            placeholder="Experience 분류 이름"
                        />
                        <div className="input-group-append">
                            {/* Save Tab Name */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="Tab 이름 저장"
                                onClick={async () => {
                                    setTab(item.at_tab_idx, tabNameInput.current.value);
                                }}
                            >
                                <i className="fas fa-save" />
                            </button>
                            {/* Add Item */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="Project 추가"
                                onClick={async () => {
                                    if (!window.confirm('새로운 Project 그룹을 생성 하시겠습니까?')) return;
                                    addExperience(item.at_tab_idx);
                                }}
                            >
                                <i className="fas fa-plus" />
                            </button>
                            {/* Delete */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="삭제"
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            '해당 Project 그룹을 전부 삭제하시겠습니까?\nProject 카테고리에 포함된 모든 Project가 모두 삭제됩니다.',
                                        )
                                    )
                                        return;
                                    delTab(item.at_tab_idx);
                                }}
                            >
                                <i className="fas fa-trash" />
                            </button>
                        </div>
                    </div>
                </h3>
                <div className="card-tools">
                    {/* Collapse */}
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                        onClick={() => setOpen(!open)}
                    >
                        <i className={open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />
                    </button>
                </div>
            </div>
            <div className="card-body p-3">
                {/* ---- Projects -------------------------------------------------------- */}
                {_.map(item.at_tab_contents, (exp, i) => (
                    <Item
                        key={'exp_' + exp.at_tc_idx}
                        item={exp}
                        setExperience={setExperience}
                        delExperience={delExperience}
                    />
                ))}
            </div>
        </div>
    );
};

/**
 * Experience 게시판  *********************************************************************
 **/
const Experience: React.FC = (): JSX.Element => {
    const [
        state,
        {
            getCheckItems,
            setSearch,
            onChoice,
            onResetChoice,
            onChecked,
            onCheckedAll,
            onReset,
            onUpdate,
            onDelete,
            onAdd,
        },
        { pages, movePage },
    ] = useList({});

    /**
     * 목록 가져오기 쿼리
     */
    // get Tab/Contents
    const [getTabList, { data }] = useLazyQuery(GETTABLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { list = [] } = data?.getTabList;
            const count = list.length;
            onReset(list, count);
        },
        onError: (err) => console.error(err),
    });

    // add Items
    const [addTabItem] = useMutation(ADDTAB_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [addContentsItem] = useMutation(ADDTABCONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    // set Tab/Contents Items
    const [setTabItem] = useMutation(SETTAB_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [setContentsItem] = useMutation(SETTABCONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    // delete Items
    const [delTabItem] = useMutation(DELETETAB_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [delContentsItem] = useMutation(DELETETABCONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    const graphql_data = {
        variables: {
            where: {
                at_tab_page: 'experience',
            },
        },
    };

    /**
     * 초기 1회 실행
     */
    useEffect(() => {
        getTabList(graphql_data);
    }, [state.search]);

    // 검색 기능 => "Tab Name(제목)"
    const handleSearch = async (search) => {
        setSearch(search);
        if (search.word.length > 0) {
            graphql_data.variables.where['at_tab_name'] = search.word;
        } else {
            delete graphql_data.variables.where['at_tab_name'];
        }
        await getTabList(graphql_data);
    };

    // 껍데기 부분
    return (
        <div className="invoice m-0 p-0">
            <div className="card-header">
                <h3 className="card-title">Athenes Experience</h3>
                {/* <div className="card-tools">
                    <SearchBox
                        inSearch={false}
                        onSearch={handleSearch}
                        placeholder="제목"
                    />
                </div> */}
            </div>

            <div className="card-body p-0">
                <ControlBox
                    onRefresh={() => {
                        getTabList(graphql_data);
                    }}
                    onAdd={async () => {
                        let res = await addTabItem({
                            variables: {
                                data: {
                                    at_tab_name: 'New_Project',
                                    at_tab_page: 'experience',
                                },
                            },
                        });

                        if (res?.errors) {
                            alert('Project 그룹 생성에 실패했습니다.');
                            return;
                        }

                        res = await addContentsItem({
                            variables: {
                                data: {
                                    at_tab_idx: res.data.addTab.at_tab_idx,
                                    at_tc_name: 'New Project',
                                    at_tc_contents: 'Project Description',
                                    at_tc_url: 'https://www.rs-team.com',
                                    at_tc_img: null,
                                    at_tc_type: 'New_Project',
                                    at_tc_sub_type: '',
                                },
                            },
                        });

                        if (res?.errors) {
                            alert('Project 생성에 실패했습니다.');
                            return;
                        }

                        getTabList(graphql_data);
                    }}
                />
                {_.map(state.view_list, (el, i) => (
                    <Collapse
                        key={'cexp_' + el.data.at_tab_idx}
                        item={el.data}
                        setTab={async (tab_idx, tab_name) => {
                            const res = await setTabItem({
                                variables: {
                                    data: {
                                        at_tab_name: tab_name,
                                    },
                                    where: {
                                        at_tab_idx: tab_idx,
                                    },
                                },
                            });
                            if (res?.errors) {
                                alert('이름 수정에 실패했습니다.');
                                return;
                            }
                            if (res?.data) {
                                alert('이름을 수정했습니다.');
                            }
                        }}
                        delTab={async (tab_idx) => {
                            const res = await delTabItem({
                                variables: {
                                    where: {
                                        at_tab_idx: tab_idx, // tabObj.at_tab_idx,
                                    },
                                },
                            });
                            if (res?.errors) {
                                alert('Project 삭제에 실패했습니다.');
                                return;
                            }
                            onDelete(null, el.id);
                            // getTabList(graphql_data);
                        }}
                        addExperience={async (tab_idx) => {
                            const res = await addContentsItem({
                                variables: {
                                    data: {
                                        at_tab_idx: tab_idx,
                                        at_tc_name: 'New Project',
                                        at_tc_contents: 'Project Description',
                                        at_tc_url: 'https://www.rs-team.com',
                                        at_tc_img: null,
                                        at_tc_type: el.data.at_tab_name,
                                        at_tc_sub_type: '',
                                    },
                                },
                            });
                            if (res?.errors) {
                                alert('Project 추가에 실패했습니다.');
                                return;
                            }
                            getTabList(graphql_data);
                        }}
                        setExperience={async (tc_idx, tc_name, tc_contents, tc_url, tc_img) => {
                            const res = await setContentsItem({
                                variables: {
                                    data: {
                                        at_tc_name: tc_name,
                                        at_tc_contents: tc_contents,
                                        at_tc_url: tc_url,
                                        at_tc_img: tc_img,
                                    },
                                    where: {
                                        at_tc_idx: tc_idx,
                                    },
                                },
                            });
                            if (res?.errors) {
                                alert('Project 수정에 실패했습니다.');
                                return;
                            }
                            if (res?.data) {
                                alert('Project가 수정되었습니다.');
                            }
                        }}
                        delExperience={async (tc_idx) => {
                            const res = await delContentsItem({
                                variables: {
                                    where: {
                                        at_tc_idx: tc_idx,
                                    },
                                },
                            });
                            if (res?.errors) {
                                alert('삭제에 실패했습니다.');
                                return;
                            }
                            getTabList(graphql_data);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Experience;
