import { gql } from '@apollo/client';

// Tab 추가
export const ADDCOLLAPSECONTENTSGROUPS_MUTATION = gql`
    mutation addCollapseContentsGroupsMutation($data: AddCollapseContentsGroupsDataDto!) {
        addCollapseContentsGroups(data: $data) {
            at_ccg_idx
            at_ccg_name
            at_ccg_description
        }
    }
`;
