import { gql } from '@apollo/client';

// Tab 추가
export const ADDCOLLAPSECONTENTS_MUTATION = gql`
    mutation addCollapseContentsMutation($data: AddCollapseContentsDataDto!) {
        addCollapseContents(data: $data) {
            at_coc_idx
            at_col_idx
            at_coc_name
            at_coc_price
            at_coc_date
            at_coc_contents
        }
    }
`;
