import { gql } from '@apollo/client';

export const GETTABLIST_QUERY = gql`
    query getTabListQuery($where: GetTabListWhereDto!) {
        getTabList(where: $where) {
            list {
                at_tab_idx
                at_tab_name
                at_tab_page
                at_tab_contents {
                    at_tc_idx
                    at_tab_idx
                    at_tc_name
                    at_tc_contents
                    at_tc_url
                    at_tc_img
                    at_tc_type
                    at_tc_sub_type
                }
            }
        }
    }
`;
