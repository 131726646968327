import React from 'react';
import cx from 'classnames';

import { is_true } from '@/helpers/common.helper';
import { FormError } from '@/components/commons/Form-error';

const Checkbox: React.FC<any> = (props): JSX.Element => {
    const {
        label = '',
        labelClassName = 'col-sm-2 col-form-label',
        groupClassName = 'form-group row',
        colClassName = 'col-sm-10',
        register,
        id,
        name,
        placeholder = '',
        defaultChecked,
        required = false,
        disabled = false,
        className = 'form-radio-input',
        icon = '',
        append = '',
        errors = {},
    } = props;
    const error = !!errors[name]; // !!는 undefined, null, 0 를 Boolean 타입으로 묵시적 변환

    return (
        <div className={groupClassName}>
            {/* label */}
            {label && (
                <label htmlFor={name} className={labelClassName}>
                    {label}
                </label>
            )}

            <div className={colClassName}>
                {/* input */}
                <input
                    ref={register}
                    id={id}
                    name={name}
                    type="checkbox"
                    placeholder={placeholder}
                    defaultChecked={is_true(defaultChecked)}
                    required={required}
                    disabled={disabled}
                    className={cx(className, {
                        'is-invalid': error,
                    })}
                />

                {/* icon */}
                {icon && (
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <span className={icon}></span>
                        </div>
                    </div>
                )}

                {/* error */}
                {errors[name]?.message && <FormError errorMessage={errors[name]?.message} name={name} />}

                {/* append */}
                {append && <span className="help-block">{append}</span>}
                {/* {append && <div className="input-group-append">{append}</div>} */}
            </div>
        </div>
    );
};
export default Checkbox;
