import { gql } from '@apollo/client';

// Tab 추가
export const SETCOLLAPSECONTENTS_MUTATION = gql`
    mutation setCollapseContentsMutation($data: SetCollapseContentsDataDto!, $where: SetCollapseContentsWhereDto!) {
        setCollapseContents(data: $data, where: $where) {
            at_coc_idx
            at_col_idx
            at_coc_name
            at_coc_price
            at_coc_date
            at_coc_contents
            at_coc_groups
        }
    }
`;
