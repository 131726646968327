import React from 'react';
import cx from 'classnames';

interface IFormErrorProps {
    errorMessage: string;
    name: string;
}

export const FormError: React.FC<IFormErrorProps> = ({ errorMessage, name }) => (
    <label className={cx('error', 'invalid-feedback')} htmlFor={name}>
        {errorMessage}
    </label>
);
