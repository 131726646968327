import { useEffect, useState } from 'react';
import _ from 'lodash';

import { useForm } from 'react-hook-form';
import MultiFileUpload from '@/components/commons/FileUploader/MultiFileUpload';
interface INews {
    id: string;
    onSubmit: (object, number, any) => void;
    loading: boolean;
    data: any;
    onClose: any;
}

const Gallery_detail: React.FC<INews> = ({ id = '', onSubmit, loading, data, onClose }): JSX.Element => {
    const [imgURLs, setImgURLs] = useState({ file_idx: null });
    //const { pst_idx = null, pst_title = null } = data;
    let fil_filename = null;
    if (data?.pst_idx) {
        fil_filename = data.files.length > 0 ? data.files[0].fil_filename : null;
    }

    const { errors, handleSubmit, formState, register } = useForm({
        mode: 'onChange',
        shouldFocusError: true,
    });

    const submitsethandler = (formData) => {
        onSubmit(formData, data.pst_idx, imgURLs.file_idx);
    };

    return (
        <div
            className="modal fade"
            id={id}
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
            aria-hidden="true"
            onClick={() => onClose()}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <i className="fas fa-globe" /> {data?.pst_title ? data?.pst_title : '새로운 Notice 작성'}
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            aria-hidden="true"
                            onClick={() => onClose()}
                        >
                            ×
                        </button>
                    </div>
                    <div className="modal-body">
                        {!data ? (
                            <>Loading...</>
                        ) : (
                            <div className="row">
                                <div className="col-12">
                                    <div className="invoice p-3 mb-0">
                                        <form onSubmit={handleSubmit(submitsethandler)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="Title">Title</label>
                                                    <input
                                                        ref={register}
                                                        type="text"
                                                        defaultValue={data.pst_title ? data.pst_title : null}
                                                        className="form-control"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Title"
                                                    />
                                                </div>
                                                <label>Img Upload</label>
                                                <div className="col-12">
                                                    {/* Project Main Image */}
                                                    <MultiFileUpload
                                                        uid={data.pst_idx}
                                                        options={{
                                                            isEnabled: true,
                                                            isDragNDrop: false,
                                                            type: 'board',
                                                            subtype: 'board1',
                                                            maxFileNum: 1,
                                                            maxDropNum: 1,
                                                            totalFileSize: 10 * 1024 * 1024,
                                                            maximumFileSize: 10 * 1024 * 1024,
                                                        }}
                                                        list={[fil_filename]}
                                                        onFinally={(data) => {
                                                            _.map(data, (file) => {
                                                                if (file?.resData?.fil_filename) {
                                                                    setImgURLs({
                                                                        ...imgURLs,
                                                                        ['file_idx']: file.resData.fil_idx[0],
                                                                    });
                                                                }
                                                                //console.log(file?.resData);
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    {data.pst_idx ? '수정' : '추가'}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gallery_detail;
