import { gql } from '@apollo/client';

// Tab 추가
export const SETCOLLAPSECONTENTSGROUPS_MUTATION = gql`
    mutation setCollapseContentsGroupsMutation(
        $data: SetCollapseContentsGroupsDataDto!
        $where: SetCollapseContentsGroupsWhereDto!
    ) {
        setCollapseContentsGroups(data: $data, where: $where) {
            at_ccg_idx
            at_ccg_name
            at_ccg_description
        }
    }
`;
