import { gql } from '@apollo/client';

// Tab 추가
export const GETCOLLAPSELISTGROUPSLIST_QUERY = gql`
    query getCollapseContentsGroupsListQuery {
        getCollapseContentsGroupsList {
            list {
                at_ccg_idx
                at_ccg_name
                at_ccg_description
            }
        }
    }
`;
