import { gql } from '@apollo/client';

// Tab Contents 삭제
export const DELETETABCONTENTS_MUTATION = gql`
    mutation deleteTabContentsMutation($where: DeleteTabContentWhereDto!) {
        deleteTabContent(where: $where) {
            at_tc_idx
        }
    }
`;
