import { Link } from 'react-router-dom';
import _ from 'lodash';

const Pagination: React.FC<any> = ({
    className,
    pages,
    onMovePage = (pageNumber) => console.log(pageNumber),
}): JSX.Element => {
    return (
        <ul className={`pagination ${className}`}>
            <li className="page-item previous">
                <button
                    type="button"
                    //to={`?page=${pages.pos_prev}`}
                    aria-controls="example2"
                    data-dt-idx={pages.pos_prev}
                    tabIndex={pages.pos_prev}
                    className="page-link text-secondary"
                    onClick={() => onMovePage(pages.pos_prev)}
                >
                    Previous
                </button>
            </li>
            {/* {console.log(
                pages.pos_begin,
                pages.pos_current,
                pages.pos_end,
                pages.pos_last,
                _.range(pages.pos_begin, pages.pos_end, 1),
            )} */}
            {_.map(_.range(pages.pos_begin, pages.pos_end, 1), (el) => {
                return (
                    <li key={el} className={`page-item ${pages.pos_current == el ? 'active' : ''}`}>
                        <button
                            type="button"
                            //to={`?page=${el + 1}`}
                            aria-controls="example2"
                            data-dt-idx={el}
                            tabIndex={el}
                            className={`page-link ${pages.pos_current == el ? 'bg-secondary' : 'text-secondary'} `}
                            onClick={() => onMovePage(el)}
                        >
                            {el + 1}
                        </button>
                    </li>
                );
            })}
            <li className="page-item next">
                <button
                    type="button"
                    //to={`?page=${pages.pos_next}`}
                    aria-controls="example2"
                    data-dt-idx={pages.pos_next}
                    tab-index={pages.pos_next}
                    className="page-link text-secondary"
                    onClick={() => onMovePage(pages.pos_next)}
                >
                    Next
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
