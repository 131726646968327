import { gql } from '@apollo/client';

// 게시물 리스트
export const GETPOSTLIST_QUERY = gql`
    query getPostListQuery(
        $skip: Float
        $take: Float
        $cursor: GetPostListCursorDto
        $orderBy: GetPostListOrderByDto
        $where: GetPostListWhereDto!
    ) {
        getPostList(skip: $skip, take: $take, cursor: $cursor, orderBy: $orderBy, where: $where) {
            board {
                brd_idx
                brd_key
                brd_name
                meta {
                    # board_skin
                    list_count
                    # mobile_list_count
                    # use_post_like
                    # use_post_dislike
                    # use_upload_file
                    # upload_file_num
                    # comment_count
                    # mobile_comment_count
                    # use_inquiry_mode
                    # use_post_secret
                    # use_comment_secret
                    # point_read
                    # point_write
                    # point_comment
                    # point_download
                    # access_list
                    # access_view
                    # access_write
                    # access_comment
                    # access_upload
                    # extravars_json
                }
            }
            post {
                count
                list {
                    pst_idx
                    # pst_brd_idx
                    pst_mem_idx
                    pst_mem_email
                    pst_mem_nickname
                    # pst_secret
                    pst_title
                    pst_content
                    # pst_thumbnail
                    pst_datetime
                    pst_updated_datetime
                    # pst_updated_timestamp
                    pst_hit
                    # writer {
                    #     mem_email
                    #     mem_nickname
                    #     mem_level
                    #     mem_point
                    # }
                    # files {
                    #     fil_idx
                    #     fil_originname
                    #     fil_path
                    #     fil_size
                    # }
                    # comment {
                    #     count
                    # }
                }
            }
        }
    }
`;
