import { gql } from '@apollo/client';

// Tab Contents 추가
export const ADDTABCONTENTS_MUTATION = gql`
    mutation addTabContentsMutation($data: AddTabContentsDataDto!) {
        addTabContents(data: $data) {
            at_tc_idx
            at_tab_idx
            at_tc_name
            at_tc_contents
            at_tc_url
            at_tc_img
            at_tc_type
            at_tc_sub_type
        }
    }
`;
