import React, { useEffect, useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useSubscription, useReactiveVar } from '@apollo/client';

import { required, email, password_pattern_1 } from '@/libraries/validate';

import Input from '@/components/commons/Inputs';
import { FormError } from '@/components/commons/Form-error';

import { loginMutation, loginMutationVariables } from '@/interfaces/apollo.interface';

import { LOGIN_MUTATION } from '@/querys/user/signIn';

import { LOCALSTORAGE_A_TOKEN, LOCALSTORAGE_LEVEL, LOCALSTORAGE_R_TOKEN } from '@/libraries/apollo/constants';
import { authTokenVar, isLoggedInVar, levelVar, client, objectVar } from '@/libraries/apollo';

// const TEST_SUBSCRIPTION = gql`
//     subscription orderUpdates($input: OrderUpdatesInput!) {
//         orderUpdates(input: $input) {
//             fragment
//         }
//     }
// `;

const Signin = (props) => {
    //const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const { register, handleSubmit, formState } = useForm({
        mode: 'onChange',
        shouldFocusError: true,
        criteriaMode: 'all',
    });
    const { errors, isDirty, isSubmitting } = formState;

    const onError = (error) => {
        console.log(error);
        // 에러 발생시
        alert(error);
    };

    const onCompleted = (data: loginMutation) => {
        //토큰 저장
        if (data) {
            localStorage.setItem(LOCALSTORAGE_A_TOKEN, data.signInUser.accessToken);
            localStorage.setItem(LOCALSTORAGE_R_TOKEN, data.signInUser.refreshToken);
            localStorage.setItem(LOCALSTORAGE_LEVEL, JSON.stringify(data.signInUser.mem_level));
            authTokenVar(data.signInUser.accessToken);
            isLoggedInVar(true);
            levelVar(JSON.stringify(data.signInUser.mem_level));

            history.push('/');
        }
    };

    // const {data: subScriptionData} = useSubscription<>(TEST_SUBSCRIPTION, {
    //     variables:{
    //         input: {
    //             id: +params.id,
    //         }
    //     }
    // });

    const [loginMutation, { data: loginMutationResult, loading }] = useMutation<loginMutation, loginMutationVariables>(
        LOGIN_MUTATION,
        {
            onCompleted,
            onError: onError,
        },
    );

    const onSubmit = async (formData: Record<string, string>): Promise<void> => {
        const { email, password } = formData;
        loginMutation({
            variables: {
                data: {
                    mem_email: email,
                    mem_password: password,
                },
            },
        });
    };

    if (useReactiveVar(isLoggedInVar)) {
        //props.history.push('/');
        //return <Redirect to={'/'} />;
        return <Redirect to={'/'} />;
    }

    return (
        // 컴포넌트 구성
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <div>RS-TEAM Admin</div>
                </div>
                {/* <!-- /.login-logo --> */}
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">로그인 후 이용 가능합니다</p>

                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="form-group">
                                {/* 이메일 */}
                                <Input
                                    id="email"
                                    name="email"
                                    type="textgroup"
                                    icon={{ back_icon: 'far fa-envelope' }}
                                    placeholder="이메일"
                                    defaultValue={''}
                                    disabled={loading}
                                    register={register({
                                        validate: {
                                            //required : required,
                                            pattern: email,
                                        },
                                    })}
                                    errors={errors}
                                />
                            </div>
                            <div className="form-group">
                                <Input
                                    id="password"
                                    name="password"
                                    type="passwordgroup"
                                    icon={{ back_icon: 'fas fa-lock' }}
                                    placeholder="비밀번호"
                                    defaultValue={''}
                                    disabled={loading}
                                    className={'form-control'}
                                    groupClassName={'input-group mb-3'}
                                    register={register({
                                        validate: {
                                            pattern: password_pattern_1,
                                        },
                                    })}
                                    errors={errors}
                                />
                                {errors.password?.message && (
                                    <FormError errorMessage={errors.password?.message} name="password" />
                                )}
                            </div>

                            <div className="row">
                                {/* <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div> */}
                                {/* <!-- /.col --> */}
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className={'btn btn-primary btn-block'}
                                        disabled={!isDirty || isSubmitting}
                                    >
                                        {loading ? <i className="fas fa-spinner fa-pulse"></i> : '로그인'}
                                    </button>
                                </div>
                                {/* <!-- /.col --> */}
                            </div>
                        </form>

                        <div className="row">
                            <div className="col-12">
                                <div className="social-auth-links text-center mb-3">
                                    <Link className="btn btn-block btn-secondary" to="/signup">
                                        회원가입
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /.social-auth-links --> */}
                        {/* <p className="text-xs-center">
                            <Link to="/findemail">아이디</Link>/<Link to="/findpw">비밀번호 찾기</Link>
                        </p> */}
                    </div>
                    {/* <!-- /.login-card-body --> */}
                </div>
            </div>
        </div>
    );
};

export default Signin;
