import React, { useState } from 'react';
import cx from 'classnames';

import { FormError } from '@/components/commons/Form-error';

const Passwd = (props: any) => {
    const {
        register,
        id,
        name,
        placeholder = '',
        defaultValue,
        required = false,
        disabled = false,
        icon = null,
        errors = {},
        onChange = () => {},
    } = props;

    const error = !!errors[name]; // !!는 undefined, null, 0 를 Boolean 타입으로 묵시적 변환
    const [visible, setVisible] = useState(true);

    return (
        <div className="input-group">
            {icon?.front_icon && (
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className={icon.front_icon}></i>
                    </span>
                </div>
            )}
            {/* input */}
            <input
                ref={register}
                id={id}
                name={name}
                type={visible ? 'password' : 'text'}
                placeholder={placeholder}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                className={cx('form-control', {
                    'is-invalid': error,
                })}
                onChange={onChange}
            />
            {/* icon */}

            <div className="input-group-append">
                <div className="input-group-text">
                    <i
                        className={visible ? 'fas fa-eye mr-2' : 'fas fa-eye-slash mr-2'}
                        onClick={() => setVisible(!visible)}
                    ></i>
                    {icon?.back_icon && <i className={icon.back_icon}></i>}
                </div>
            </div>

            {/* error */}
            {errors[name]?.message && <FormError errorMessage={errors[name]?.message} name={name} />}
        </div>
    );
};
export default Passwd;
