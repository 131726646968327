import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { htmlspecialchars_decode } from 'locutus/php/strings';
import moment from 'moment';
import useList from '@/hooks/useList.hook';

/**
 * Import Common Components
 */
import SearchBox from '@/components/commons/SearchBox';
import ControlBox from '@/components/commons/ControlBox';
import Pagination from '@/components/commons/Pagination_0';

import Contact_Detail from './contact_detail';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GETPOSTLIST_QUERY } from '@/querys/board/post/getPostList';
import { DELETEPOSTMANY_MUTATION } from '@/querys/board/post/deletePostMany';
import { GETPOSTONE_QUERY } from '@/querys/board/post/getPostOne';
import { deletePostManyMutation, deletePostManyMutationVariables } from '@/interfaces/apollo.interface';

const Contact: React.FC = (): JSX.Element => {
    // 리스트 훅
    const [
        state,
        {
            getCheckItems,
            setSearch,
            onChoice,
            onResetChoice,
            onChecked,
            onCheckedAll,
            onReset,
            onUpdate,
            onDelete,
            onAdd,
        },
        { pages, movePage },
    ] = useList({});

    const dialogId = 'rscontactModalCenter';
    const dialogModalRef = useRef(null);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [viewDetial, setViewDetial] = useState(null);
    const [graphql_data, setGraphql_data] = useState({
        variables: {
            skip: pages.pos_current * pages.cnt_print,
            take: pages.cnt_print,
            where: {
                brd_key: 'contactus',
            },
        },
    });

    const [getList, { data, loading, error }] = useLazyQuery(GETPOSTLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            console.log('cont act refeash');
            // //console.log(data.getPostList.post.count);
            const { list = [], count = 0 } = data?.getPostList?.post;
            onReset(list, count);
        },
    });

    const [getItem] = useLazyQuery(GETPOSTONE_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const obj = JSON.parse(data.getPostOne.post.pst_content);
            obj['pst_idx'] = data.getPostOne.post.pst_idx;
            obj['pst_datetime'] = data.getPostOne.post.pst_datetime;
            setViewDetial(obj);
            // setViewDetial({
            //     contents: JSON.parse(data.getPostOne.post.pst_content),
            //     data: data.getPostOne.post,
            // });
        },
    });

    // 글 삭제기능
    const [deletePostManyMutation] = useMutation<deletePostManyMutation, deletePostManyMutationVariables>(
        DELETEPOSTMANY_MUTATION,
        {
            onError: (err) => console.error(err),
        },
    );

    /**
     * 초기 1회 실행
     */
    useEffect(() => {
        getList(graphql_data);
    }, []);

    //검색기능
    const handleSearch = async (search) => {
        if (search.word.length > 0) {
            if (!(search.word.length > 1 && search.word.length <= 50)) {
                window.alert('검색어는 2~50자 까지입니다.');
                return;
            }
        }

        await setSearch(search);

        const _graphql_data = graphql_data;
        if (search.word.length > 0) {
            _graphql_data.variables.where['pst_title'] = search.word;
        } else {
            delete _graphql_data.variables.where['pst_title'];
        }
        _graphql_data.variables.skip = 0 * pages.cnt_print;
        _graphql_data.variables.take = pages.cnt_print;
        setGraphql_data(_graphql_data);
        movePage(0);
        await getList(graphql_data);
    };

    const handlePage = async (currentPage) => {
        await movePage(currentPage);

        const _graphql_data = graphql_data;
        _graphql_data.variables.skip = currentPage * pages.cnt_print;
        _graphql_data.variables.take = pages.cnt_print;
        setGraphql_data(_graphql_data);
        await getList(graphql_data);
    };

    return (
        <>
            {/* 디테일 창 여는 버튼 */}
            <button
                ref={dialogModalRef}
                style={{ display: 'none' }}
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target={'#' + dialogId}
            >
                dialogModaToggle
            </button>
            <Contact_Detail
                id={dialogId}
                data={viewDetial}
                onClose={() => {
                    //setViewDetial(null);
                    onResetChoice();
                }}
            />
            {/* {viewDetial && <Contact_Detail data={viewDetial} onClose={() => setViewDetial(null)} />} */}

            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">RS-Team Contact</h3>
                    <div className="card-tools">
                        <SearchBox inSearch={false} onSearch={handleSearch} placeholder="제목 검색" />
                    </div>
                </div>
                <div className="card-body p-0">
                    <ControlBox
                        // onCheckAll={(check) => {
                        //     onCheckedAll(check);
                        //     //console.log('cb');
                        // }}
                        onRemove={async () => {
                            if (!confirm(`모든 항목을 삭제하시겠습니까?`)) return;
                            const delList = getCheckItems();
                            const pst_idxs = _.map(delList, (el) => el.data.pst_idx);
                            //비동기 처리
                            const result = await deletePostManyMutation({
                                variables: {
                                    where: {
                                        pst_idx: pst_idxs,
                                    },
                                },
                            });
                            if (result?.errors) {
                                alert('삭제에 실패했습니다.');
                                return;
                            }
                            //_.forEach(delList, (el, i) => onDelete(null, i));
                            //성공시 onDelete
                            getList(graphql_data);
                        }}
                        onRefresh={() => {
                            getList(graphql_data);
                        }}
                    />
                    <table className="table table-striped table-hover text-nowrap">
                        <colgroup>
                            <col width={'20px'} />
                            <col width={'30px'} />
                            <col width={'20%'} />
                            <col width={'*'} />
                            <col width={'16%'} />
                            <col width={'25px'} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div className="icheck-primary d-inline">
                                        <input
                                            id="checkboxAll_cnt_rscontact"
                                            type="checkbox"
                                            defaultChecked={checkAll}
                                            //checked={checkAll}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onCheckedAll(!checkAll);
                                                setCheckAll(!checkAll);
                                            }}
                                        />
                                        <label htmlFor={'checkboxAll_cnt_rscontact'}></label>
                                    </div>
                                </th>
                                <th>#</th>
                                <th>Email</th>
                                <th>Title</th>
                                <th>Date</th>
                                <th>
                                    <i className={'fas fa-trash'} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(state.view_list, (el, i) => {
                                return (
                                    <tr
                                        key={'rscontact_' + i}
                                        className={el.active ? 'bg-primary disabled' : ''}
                                        id={i}
                                        onClick={(e) => {
                                            const _data = onChoice(e);
                                            getItem({
                                                variables: {
                                                    where: {
                                                        pst_idx: _data.data.pst_idx,
                                                    },
                                                    option: {
                                                        is_view: true,
                                                    },
                                                },
                                            });
                                            dialogModalRef.current.click();
                                        }}
                                    >
                                        <td>
                                            <div
                                                className="icheck-primary d-inline"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <input
                                                    id={'rscntchk_' + i}
                                                    name={i}
                                                    type="checkbox"
                                                    checked={el.checked}
                                                    onChange={onChecked}
                                                />
                                                <label htmlFor={'rscntchk_' + i}></label>
                                            </div>
                                        </td>
                                        <td>{el.data.pst_idx}</td>
                                        <td>{el.data.pst_mem_email}</td>
                                        <td>{htmlspecialchars_decode(el.data.pst_title)}</td>
                                        <td>{moment(el.data.pst_datetime).format('YYYY-MM-DD')}</td>
                                        <td>
                                            <button
                                                id={i}
                                                type="button"
                                                className="btn btn-block bg-gradient-danger btn-xs"
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    if (!confirm(`${el.data.pst_idx}번 항목을 삭제하시겠습니까?`))
                                                        return;

                                                    const res = await deletePostManyMutation({
                                                        variables: {
                                                            where: {
                                                                pst_idx: el.data.pst_idx,
                                                            },
                                                        },
                                                    });
                                                    if (res?.errors) {
                                                        alert('삭제에 실패했습니다.');
                                                        return;
                                                    }
                                                    // if (res?.data?.deletePostMany?.pst_idx[0] === el.data.pst_idx) {
                                                    //     onDelete(e, el.id);
                                                    // }
                                                    getList(graphql_data);
                                                }}
                                            >
                                                <i className={'fas fa-trash m-1'} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination className="m-3" pages={pages} onMovePage={(pageNumber) => handlePage(pageNumber)} />
            </div>
        </>
    );
};
export default Contact;
