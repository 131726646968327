import { gql } from '@apollo/client';

// Tab 추가
export const ADDTAB_MUTATION = gql`
    mutation addTabMutation($data: AddTabDataDto!) {
        addTab(data: $data) {
            at_tab_idx
            at_tab_name
            at_tab_page
        }
    }
`;
