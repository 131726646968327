import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { phoneDashFomatter, replaceFirstNumbersNotZero, numberWithCommas } from '@/helpers/common.helper';
import Instance from '@/helpers/axiosInstance.helper';

const Contact_Detail: React.FC<any> = ({ data, onClose, id = '' }): JSX.Element => {
    // let totalPrice = 0;

    return (
        <div
            className="modal fade"
            id={id}
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
            aria-hidden="true"
            onClick={() => onClose()}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <i className="fas fa-globe" /> {data && data.company} 문의 내역
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            aria-hidden="true"
                            onClick={() => onClose()}
                        >
                            ×
                        </button>
                    </div>
                    <div className="modal-body">
                        {!data ? (
                            <>Loading...</>
                        ) : (
                            <div className="row">
                                <div className="invoice p-3 mb-0">
                                    {/* <!-- title row --> */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            {data.question_type_1 && (
                                                <span className="badge bg-teal m-1 p-1">
                                                    <small>프로젝트 의뢰</small>
                                                </span>
                                            )}
                                            {data.question_type_2 && (
                                                <span className="badge bg-info m-1 p-1">문의 사항</span>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            {data.project_type_1 && (
                                                <span className="badge bg-indigo m-1 p-1">웹사이트</span>
                                            )}
                                            {data.project_type_2 && (
                                                <span className="badge bg-lightblue m-1 p-1">웹퍼블리싱</span>
                                            )}
                                            {data.project_type_3 && (
                                                <span className="badge bg-orange m-1 p-1">앱개발</span>
                                            )}
                                            {data.project_type_4 && (
                                                <span className="badge bg-olive m-1 p-1">디자인요청</span>
                                            )}
                                            {data.project_type_5 && (
                                                <span className="badge bg-danger m-1 p-1">유지보수&리뉴얼</span>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            <div className="callout callout-info">
                                                <h5>
                                                    <i className="fas fa-info"></i> Note:
                                                </h5>
                                                <p className="text-muted well well-sm shadow-none">{data.contants}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- info row --> */}
                                    <div className="row invoice-info">
                                        <div className="col-sm-5 invoice-col">
                                            From
                                            <address>
                                                <b>회사명: </b>
                                                {data.company}
                                                <br />
                                                <b>담당자명: </b>
                                                {data.name}
                                                <br />
                                                <b>연락처: </b>
                                                {phoneDashFomatter(data.phone)}
                                                <br />
                                                <b>Email: </b>
                                                {data.email}
                                            </address>
                                        </div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-sm-2 invoice-col"></div>
                                        {/* <!-- /.col --> */}
                                        <div className="col-sm-5 invoice-col">
                                            <br />
                                            <br />
                                            <br />
                                            <b>Order ID: </b>
                                            {data.pst_idx}
                                            <br />
                                            <b>Request Date: </b>
                                            {moment(data.pst_datetime).format('YYYY/MM/DD HH:mm')}
                                        </div>
                                        {/* <!-- /.col --> */}
                                    </div>
                                    {/* <!-- /.row --> */}

                                    {/* <!-- Table row --> */}
                                    {/* <div className="row">
                                        <div className="col-12 table-responsive">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>기능명</th>
                                                        <th>개발기간</th>
                                                        <th>가격</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {_.size(data.check_data) <= 0 && (
                                                        <tr className="text-center">
                                                            <td colSpan={4}>선택 견적이 없습니다.</td>
                                                        </tr>
                                                    )}
                                                    {_.map(data.check_data, (el, i) => {
                                                        totalPrice += replaceFirstNumbersNotZero(el.at_coc_price);
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{el.at_coc_name}</td>
                                                                <td>{el.at_coc_date}</td>
                                                                <td>
                                                                    {numberWithCommas(
                                                                        replaceFirstNumbersNotZero(el.at_coc_price),
                                                                        '원',
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}

                                    <div className="row">
                                        {/* <!-- accepted payments column --> */}
                                        <div className="col-6">
                                            <p className="lead">첨부 파일</p>

                                            {_.size(data.file_list) <= 0 ? (
                                                <p>없음</p>
                                            ) : (
                                                _.map(data.file_list, (el, i) => {
                                                    if (el === null) return <></>;
                                                    return (
                                                        <a
                                                            key={i}
                                                            className="text-gray m-1"
                                                            onClick={() => {
                                                                const instance = new Instance();

                                                                instance.setOptions({
                                                                    method: 'GET',
                                                                    url: '/PDFdownloads/' + el.fil_filename, // + "?token=" + token,
                                                                    responseType: 'blob',
                                                                    headers: {
                                                                        ['Authorization']: 'Bearer ' + 'rsteam_admin',
                                                                        // ["Access-Control-Allow-Origin"]: "*",
                                                                        // ["Content-Type"]: "multipart/form-data",
                                                                        //["User-Agent"] : window.navigator.userAgent,
                                                                        //["Content-Length"] = 0,
                                                                    },
                                                                    // onDownloadProgress: async (e) => {
                                                                    //     console.log(e);
                                                                    // },
                                                                });

                                                                instance.request({
                                                                    onComplete: (res) => {
                                                                        switch (res.status) {
                                                                            case 200:
                                                                            case 201:
                                                                                const url = window.URL.createObjectURL(
                                                                                    new Blob([res.data]),
                                                                                );
                                                                                const link =
                                                                                    document.createElement('a');
                                                                                link.href = url;
                                                                                link.setAttribute(
                                                                                    'download',
                                                                                    el.fil_originname,
                                                                                ); //or any other extension
                                                                                document.body.appendChild(link);
                                                                                link.click();
                                                                                break;
                                                                            default:
                                                                            //coconsole.log.data);
                                                                        }
                                                                    },
                                                                    onError: async (err) => {
                                                                        console.log(err);
                                                                        //_state.error = err;
                                                                        //setFiles([...files, _state]);
                                                                    },
                                                                    onFinally: async () => {
                                                                        //console.log("download Done");
                                                                    },
                                                                });
                                                            }}
                                                            download={true}
                                                        >
                                                            <i className="fas fa-file fa-2x"></i>
                                                        </a>
                                                    );
                                                })
                                            )}
                                        </div>
                                        {/* <!-- /.col --> */}
                                        {/* <div className="col-6">
                                            <p className="lead"></p>

                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>견적가:</th>
                                                            <td>{numberWithCommas(totalPrice, '원')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>VAT (10%)</th>
                                                            <td>
                                                                {numberWithCommas(Math.floor(totalPrice * 0.1), '원')}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>총 견적:</th>
                                                            <td>
                                                                <b>
                                                                    {numberWithCommas(
                                                                        Math.floor(totalPrice * 1.1),
                                                                        '원',
                                                                    )}
                                                                </b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> */}
                                        {/* <!-- /.col --> */}
                                    </div>
                                    {/* <!-- /.row --> */}

                                    {/* <!-- this row will not appear when printing --> */}
                                    <div className="row no-print">
                                        <div className="col-12">
                                            {/* <a
                                        href="invoice-print.html"
                                        rel="noopener"
                                        target="_blank"
                                        className="btn btn-default"
                                    >
                                        <i className="fas fa-print"></i>
                                        Print
                                    </a>
                                    <button
                                        type="button"
                                        className="btn btn-success float-right"
                                    >
                                        <i className="far fa-credit-card"></i>
                                        Submit Payment
                                    </button> */}
                                            {/* <button
                                    type="button"
                                    className="btn btn-primary float-right"
                                >
                                    <i className="fas fa-download"></i>
                                    Generate PDF
                                </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className="modal-footer">
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default Contact_Detail;
