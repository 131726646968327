const Footer = () => {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>Version</b> 3.0.5-rc
            </div>
            <strong>
                Copyright &copy; 2014-2020 <a href="https://adminlte.io">AdminLTE.io</a>.
            </strong>{' '}
            All rights reserved.
        </footer>
    );
};

export default Footer;
