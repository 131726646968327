import Header from './Header';
import SideBarMain from './Sidebar_main';
import Footer from './Footer';
import SideBarContents from './Sidebar_contents';
import { Helmet } from 'react-helmet-async';

import '@/styles/adminlte.min.css';
// import "../../../jquery.js"
// import "../../../jquery.min.js"

/**
 * Admin LTE 메인 레이아웃 컴포넌트
 *
 *  ---------------------------------------------------------------------
 *  layout function             : CSS3 Class Name
 *  ---------------------------------------------------------------------
 *  도움말 : https://adminlte.io/themes/v3/index3.html -> layout options 메뉴 참조
 *  ---------------------------------------------------------------------
 *  1. Top Navigation           : layout-top-nav
 *  2. Top Navigation + sidebar : sidebar-collapse layout-top-nav
 *  3. boxed                    : sidebar-mini layout-boxed
 *  4. fixed sidebar            : sidebar-mini layout-fixed
 *  5. fixed navbar             : sidebar-mini layout-navbar-fixed
 *  6. fixed footer             : sidebar-mini layout-footer-fixed
 *  7. Collapsed Sidebar        : sidebar-mini sidebar-collapse
 *  ---------------------------------------------------------------------
 *  class name은 조합 가능합니다.
 *  ex) <body className="hold-transition sidebar-mini layout-navbar-fixed layout-footer-fixed">
 *   --> 로딩시 메인 메뉴 최소화, 해더, 푸터 화면 고정
 */

const menuSet = [
    {
        name: '처음으로',
        path: '/',
        headerIcon: 'fas fa-home',
        footerIcon: '',
        submenus: null,
    },
    {
        name: 'RS-Team Website',
        path: '#',
        headerIcon: 'fas fa-cog',
        footerIcon: 'fas fa-angle-left',
        type: 'config',
        submenus: [
            {
                name: 'Portfolio',
                path: '/website/portfolio',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['userinfo', 'usersignup', 'userdormant'],
            },
            {
                name: 'Diary',
                path: '/website/diary',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['userinfo', 'usersignup', 'userdormant'],
            },
            {
                name: 'Notice',
                path: '/website/notice',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['userinfo', 'usersignup', 'userdormant'],
            },
            {
                name: 'Activity',
                path: '/website/activity',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['userinfo', 'usersignup', 'userdormant'],
            },
            {
                name: 'Contect Us',
                path: '/website/contact',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['userinfo', 'usersignup', 'userdormant'],
            },
        ],
    },
    {
        name: 'Athenes Website',
        path: '#',
        headerIcon: 'fas fa-cog',
        footerIcon: 'fas fa-angle-left',
        type: 'member',
        submenus: [
            {
                name: '프로젝트 기능 관리',
                path: '/athenes/function',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['member', 'adduser'],
            },
            {
                name: 'Experience/Portfolio',
                path: '/athenes/experience',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['member', 'adduser'],
            },
            {
                name: 'QnA 관리',
                path: '/athenes/qna',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['member', 'adduser'],
            },
            {
                name: 'Contect Us',
                path: '/athenes/contact',
                headerIcon: '',
                footerIcon: '',
                submenus: null,
                type: ['member', 'adduser'],
            },
        ],
    },
];

const AdminLayout = (props: any) => {
    const { children } = props;
    const { classs = 'hold-transition sidebar-mini layout-navbar-fixed layout-footer-fixed' } = props;

    return (
        <div className={classs}>
            <Helmet>
                <title>RS | Main</title>
            </Helmet>
            <div className="wrapper">
                {/* 페이지 해더 */}
                <Header />
                {/* 좌측 메인 메뉴 */}
                <SideBarMain menus={menuSet} />

                {/* 메인 컨텐츠 영역 */}
                <div className="content-wrapper">{children}</div>

                {/* 페이지 푸터 */}
                <Footer />
                {/* 우측 컨탠츠 서브 메뉴 */}
                <SideBarContents />
            </div>
        </div>
    );
};

export default AdminLayout;
