// import { ApolloClient, InMemoryCache } from '@apollo/client';

// export const client = new ApolloClient({
//     uri: 'http://localhost:3000/graphql',
//     cache: new InMemoryCache(),
// });

import { ApolloClient, InMemoryCache, makeVar, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { LOCALSTORAGE_A_TOKEN, LOCALSTORAGE_LEVEL } from './constants';

const token = localStorage.getItem(LOCALSTORAGE_A_TOKEN);
const level = localStorage.getItem(LOCALSTORAGE_LEVEL);
export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);
export const levelVar = makeVar(level);

export const objectVar = makeVar({});

// const wsLink = new WebSocketLink({
//     uri:
//         process.env.NODE_ENV === 'production'
//             ? process.env.REACT_APP_PRODUCTION_WS_GRAPHQL_URL
//             : process.env.REACT_APP_DEVELOPMENT_WS_GRAPHQL_URL,
//     options: {
//         reconnect: true,
//         connectionParams: {
//             Authorization: authTokenVar() || '',
//         },
//     },
// });

const httpLink = createHttpLink({
    uri:
        process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_PRODUCTION_HTTP_GRAPHQL_URL
            : process.env.REACT_APP_DEVELOPMENT_HTTP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            Authorization: authTokenVar() || '',
        },
    };
});

// const splitLink = split(
//     ({ query }) => {
//         const definition = getMainDefinition(query);
//         return (
//             definition.kind === 'OperationDefinition' &&
//             definition.operation === 'subscription'
//         );
//     },
//     wsLink,
//     authLink.concat(httpLink),
// );

export const client = new ApolloClient({
    // link: splitLink,
    // uri: 'http://localhost:3100/graphql',
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    isLoggedIn: {
                        read() {
                            return isLoggedInVar();
                        },
                    },
                    token: {
                        read() {
                            return authTokenVar();
                        },
                    },
                    level: {
                        read() {
                            return levelVar();
                        },
                    },
                },
            },
        },
    }),
});
