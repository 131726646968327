import React, { useState } from 'react';

const ControlBox = ({
    onCheckAll = null,
    onRemove = null,
    onPrev = null,
    onNext = null,
    onRefresh = null,
    onPrevPage = null,
    onNextPage = null,
    onAdd = null,
}) => {
    const [check, setCheckAll] = useState(false);

    const _handleCheckAll = (_check) => {
        onCheckAll(!_check);
        setCheckAll(!_check);
    };

    return (
        <div className="mailbox-controls">
            {/* <!-- Check all button --> */}
            {onCheckAll && (
                <div className="btn-group">
                    <button
                        type="button"
                        className="btn btn-default btn-sm checkbox-toggle"
                        onClick={() => _handleCheckAll(check)}
                    >
                        <i className={check ? 'far fa-check-square' : 'far fa-square'} />
                    </button>
                </div>
            )}
            {(onRemove || onPrev || onNext || onRefresh) && (
                <div className="btn-group">
                    {onRemove && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onRemove}>
                            <i className="far fa-trash-alt" />
                        </button>
                    )}
                    {onPrev && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onPrev}>
                            <i className="fas fa-reply" />
                        </button>
                    )}
                    {onNext && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onNext}>
                            <i className="fas fa-share" />
                        </button>
                    )}
                    {onRefresh && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onRefresh}>
                            <i className="fas fa-sync-alt" />
                        </button>
                    )}
                </div>
            )}
            {/* <!-- /.btn-group --> */}

            {(onPrevPage || onNextPage || onAdd) && (
                <div className="btn-group float-right">
                    {(onPrevPage || onNextPage) && '1-50/200'}
                    {onPrevPage && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onPrevPage}>
                            <i className="fas fa-chevron-left" />
                        </button>
                    )}
                    {onNextPage && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onNextPage}>
                            <i className="fas fa-chevron-right" />
                        </button>
                    )}
                    {onAdd && (
                        <button type="button" className="btn btn-default btn-sm" onClick={onAdd}>
                            <i className="fas fa-plus" />
                        </button>
                    )}
                    {/* <!-- /.btn-group --> */}
                </div>
            )}
        </div>
    );
};
export default ControlBox;
