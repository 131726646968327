import { gql } from '@apollo/client';

// Tab 추가
export const ADDCOLLAPSE_MUTATION = gql`
    mutation addCollapseMutation($data: AddCollapseDataDto!) {
        addCollapse(data: $data) {
            at_col_idx
            at_col_name
            at_col_sub_name
        }
    }
`;
