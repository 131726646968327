import React from 'react';
import cx from 'classnames';

import { FormError } from '@/components/commons/Form-error';

const Email: React.FC<any> = (props): JSX.Element => {
    const { name, groupClassName, colClassName, labelClassName, label, icon, errors, append } = props;

    return (
        <div className={groupClassName}>
            <div className={colClassName}>
                {/* input */}
                <input {...props} />

                {/* label */}
                {label && (
                    <label htmlFor={name} className={labelClassName}>
                        {label}
                    </label>
                )}

                {/* icon */}
                {icon && (
                    <div className="input-group-append">
                        <div className="input-group-text">
                            <span className={icon}></span>
                        </div>
                    </div>
                )}

                {/* error */}
                {errors[name]?.message && <FormError errorMessage={errors[name]?.message} name={name} />}

                {/* append */}
                {append && <div className="input-group-append">{append}</div>}
            </div>
        </div>
    );
};
export default Email;
