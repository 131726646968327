const SideBar = () => {
    return (
        <aside className="control-sidebar control-sidebar-dark">
            {/* <!-- Control sidebar content goes here --> */}
            이곳은 사이드 바 2
        </aside>
    );
};

export default SideBar;
