import _ from 'lodash';
import Instance from '@/helpers/axiosInstance.helper';
import { initializeApollo } from '@/libraries/apolloClient';
import { print } from 'graphql';
import { DELETE_FILE_ONE_MUTATION } from '@/querys/fileupload/deleteFileOne';

class FileService {
    constructor() {}

    upload = (
        type,
        subtype,
        file,
        callback = {
            onUploadProgress: (e) => console.log(e),
            onComplete: (res) => console.log(res),
            onError: (err) => console.log(err),
            onFinally: () => console.log('finally'),
        },
        enableToken = false,
    ) => {
        const token = window?.localStorage['rs-a-token'];
        if (enableToken) {
            if (!token) {
                console.log('Do not Token for upload');
                return 0;
            }
        }

        // 업로드 상태 변경
        file.isUploading = true;
        /**
         * 옵션 설정
         */
        const formData = new FormData();
        formData.append('anyfile[]', file.file);
        formData.append('type', type);
        formData.append('subtype', subtype);

        const instance = new Instance();

        const _headers = {
            ['Access-Control-Allow-Origin']: '*',
            ['Content-Type']: 'multipart/form-data',
            //["User-Agent"] : window.navigator.userAgent,
            //["Content-Length"] = 0,
        };

        if (enableToken) _headers['Authorization'] = 'Bearer ' + token;

        instance.setOptions({
            data: formData,
            method: 'POST',
            url: '/upload/uploadanyfile',
            headers: _headers,
            onUploadProgress: callback.onUploadProgress,
        });
        /**
         * 파일 업로드
         */
        return instance.request({
            onComplete: callback.onComplete,
            onError: callback.onError,
            onFinally: callback.onFinally,
        });
    };

    downlaod = (filename) => {
        const token = window?.localStorage['rs-a-token'];
        if (!token) {
            console.log('Do not Token for download');
            return 0;
        }

        const instance = new Instance();
        instance.setOptions({
            method: 'GET',
            url: '/downloads/' + filename, // + "?token=" + token,
            responseType: 'blob',
            headers: {
                ['Authorization']: 'Bearer ' + token,
                // ["Access-Control-Allow-Origin"]: "*",
                // ["Content-Type"]: "multipart/form-data",
                //["User-Agent"] : window.navigator.userAgent,
                //["Content-Length"] = 0,
            },
            // onDownloadProgress: async (e) => {
            //     console.log(e);
            // },
        });
        /**
         * 파일 다운로드
         */
        instance.request({
            onComplete: (res) => {
                switch (res.status) {
                    case 200:
                    case 201:
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        break;
                    default:
                        console.log(res.data);
                }
            },
            onError: async (err) => {
                console.log(err);
                //_state.error = err;
                //setFiles([...files, _state]);
            },
            onFinally: async () => {
                //console.log("download Done");
            },
        });
    };

    showImage = (elementId, filename) => {
        if (document.getElementById(elementId) !== null) return;

        const token = window?.localStorage['rs-a-token'];
        if (!token) {
            console.log('Do not Token for download');
            return 0;
        }

        const instance = new Instance();
        instance.setOptions({
            method: 'GET',
            url: '/PDFdownloads/' + filename, // + "?token=" + token,
            responseType: 'blob',
            headers: {
                ['Authorization']: 'Bearer ' + token,
                // ["Access-Control-Allow-Origin"]: "*",
                // ["Content-Type"]: "multipart/form-data",
                //["User-Agent"] : window.navigator.userAgent,
                //["Content-Length"] = 0,
            },
            // onDownloadProgress: async (e) => {
            //     console.log(e);
            // },
        });
        /**
         * 파일 다운로드
         */
        instance.request({
            onComplete: (res) => {
                switch (res.status) {
                    case 200:
                    case 201:
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const imgTag = document.getElementById(elementId);
                        imgTag.setAttribute('src', url); //or any other extension
                        break;
                    default:
                        console.log(res.data);
                }
            },
            onError: async (err) => {
                console.log(err);
                //_state.error = err;
                //setFiles([...files, _state]);
            },
            onFinally: async () => {
                //console.log("download Done");
            },
        });
    };

    delete = (
        idx,
        callback = {
            onComplete: (res) => console.log(res),
            onError: (err) => console.log(err),
            onFinally: () => console.log('finally'),
        },
    ) => {
        // const client = initializeApollo(null, null, window?.localStorage['rs-a-token']);
        // client
        //     .mutate({
        //         mutation: DELETE_FILE_ONE_MUTATION,
        //         variables: {
        //             where: {
        //                 fil_idx: idx,
        //             },
        //         },
        //     })
        //     .then(callback.onComplete)
        //     .catch(callback.onError)
        //     .finally(callback.onFinally);
        console.log('-----------------------');
        const token = window?.localStorage['rs-a-token'];
        if (!token) {
            console.log('Do not Token for download');
            return 0;
        }

        const instance = new Instance();
        instance.setOptions({
            method: 'POST',
            url: '/graphql',
            headers: {
                ['Content-Type']: 'application/json',
                ['Authorization']: 'Bearer ' + token,
            },
            data: {
                query: print(DELETE_FILE_ONE_MUTATION),
                variables: {
                    where: {
                        fil_idx: idx,
                    },
                },
            },
        });
        /**
         * 파일 다운로드
         */
        instance.request({
            onComplete: callback.onComplete,
            onError: callback.onError,
            onFinally: callback.onFinally,
        });
    };
}

export default new FileService();
