import * as _ from 'lodash';
import cx from 'classnames';

import { Link } from 'react-router-dom';

import { useEffect, useRef } from 'react';

const defaultData = {
    title: 'Page Title',
    header: 'Header Title',
    custom: {
        top: 'top headline',
        bottom: 'bottom headline',
    },
};

const TabSections = (props: any) => {
    const options = props?.options ? props.options : defaultData;
    const childrens = props?.children ? props.children : null;
    // const router = useRouter();

    const onClick = (path) => {
        // router.push(path);
    };

    const generateTabHead = () => {
        return _.map(childrens, (children, i) => {
            const { id, name, active, type }: any = children.props;

            return (
                <li key={i} className="nav-item">
                    <Link
                        to={`${options.pathname}/${type}`}
                        className={cx('nav-link', 'text-secondary', active === type && 'active')}
                        id={'custom-content-above-' + type + '-tab'}
                        role="tab"
                        aria-controls={'#custom-content-above-' + type}
                        aria-selected={active}
                    >
                        {name}
                    </Link>
                </li>
            );
        });
    };
    const generateTabBody = () => {
        return _.map(childrens, (children, i) => {
            const { type, name, active } = children.props;

            return (
                <div
                    key={i}
                    className={cx('tab-pane fade', active === type && 'active show')}
                    id={'custom-content-above-' + type}
                    role="tabpanel"
                    aria-labelledby={'custom-content-above-' + type + '-tab'}
                >
                    {children}
                </div>
            );
        });
    };

    return (
        <div>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{options.title}</h1>
                        </div>
                        {/* Link */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">{options.path}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-secondary card-outline">
                        {options?.header && (
                            <div className="card-header">
                                <h3 className="card-title">
                                    <i className="fas fa-edit"></i>
                                    {options.header}
                                </h3>
                            </div>
                        )}
                        <div className="card-body">
                            <ul className="nav nav-tabs" id="custom-content-above-tab" role="tablist">
                                {generateTabHead()}
                            </ul>
                            {options?.custom?.top && (
                                <div className="tab-custom-content">
                                    <p className="lead mb-0">{options?.custom?.top}</p>
                                </div>
                            )}
                            <div className="tab-content" id="custom-content-above-tabContent">
                                {generateTabBody()}
                            </div>
                            {options?.custom?.top && (
                                <div className="tab-custom-content">
                                    <p className="lead mb-0">{options?.custom?.bottom}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

const Tab = ({ id, active = false, name, type, children }: any) => {
    return (
        <div
            className={cx('tab-pane fade active show')}
            id={'custom-content-above-' + type}
            role="tabpanel"
            aria-labelledby={'custom-content-above-' + type + '-tab'}
        >
            {children}
        </div>
    );
};

export { TabSections, Tab };
