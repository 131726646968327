import { gql } from '@apollo/client';

// Tab 추가
export const DELETECOLLAPSECONTENTSGROUPS_MUTATION = gql`
    mutation deleteCollapseContentsGroupsMutation($where: DeleteCollapseContentsGroupsWhereDto!) {
        deleteCollapseContentsGroups(where: $where) {
            at_ccg_idx
        }
    }
`;
