import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { gql, useMutation, useSubscription } from '@apollo/client';

import {
    required,
    email,
    alphabet_number_pattern,
    password_pattern_1,
    compare_password,
    minLength,
    maxLength,
} from '@/libraries/validate';

import Input from '@/components/commons/Inputs';
import CustomLink from '@/components/commons/CustomLink';
import { FormError } from '@/components/commons/Form-error';

import {
    createAccountMutation,
    createAccountMutationVariables,
    loginMutation,
    loginMutationVariables,
} from '@/interfaces/apollo.interface';

import { LOGIN_MUTATION } from '@/querys/user/signIn';

import { LOCALSTORAGE_A_TOKEN, LOCALSTORAGE_LEVEL, LOCALSTORAGE_R_TOKEN } from '@/libraries/apollo/constants';
import { authTokenVar, isLoggedInVar, levelVar, client, objectVar } from '@/libraries/apollo';
import { CREATE_ACCOUNT_MUTATION } from '@/querys/user/signUp';

const Signup = () => {
    const { register, handleSubmit, formState, getValues, setValue } = useForm({
        mode: 'all',
        shouldFocusError: true,
        criteriaMode: 'all',
    });
    const { errors, isDirty, isSubmitting } = formState;

    const onError = (error) => {
        // 에러 발생시
        alert(error);
    };

    const onCompleted = (data: createAccountMutation) => {
        if (data) {
            localStorage.setItem(LOCALSTORAGE_LEVEL, JSON.stringify(data.signUpUser.mem_level));
            isLoggedInVar(true);
            levelVar(JSON.stringify(data.signUpUser.mem_level));

            alert('회원가입이 완료되었습니다.');
            location.replace('/signin');
        }
    };

    const [createAccountMutation, { data: createAccountMutationResult, loading }] = useMutation<
        createAccountMutation,
        createAccountMutationVariables
    >(CREATE_ACCOUNT_MUTATION, {
        onCompleted,
        onError,
    });

    const onSubmit = async (formData: Record<string, string>): Promise<void> => {
        const { email, username, password, ...ect } = formData;
        createAccountMutation({
            variables: {
                data: {
                    mem_email: email,
                    mem_nickname: username,
                    mem_password: password,
                    mem_phone: email,
                    extravars: [
                        {
                            mev_key: '',
                            mev_value: '',
                        },
                    ],
                    mem_kakao_agreement: 0,
                    mem_sms_agreement: 0,
                },
            },
        });
    };

    return (
        <div className="register-page">
            <div className="register-box">
                <div className="register-logo">
                    <div>RS-TEAM Admin</div>
                </div>
                <div className="card">
                    <div className="card-body register-card-body">
                        <p className="login-box-msg">회원 가입</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                {/* 이메일 */}
                                <Input
                                    id="email"
                                    name="email"
                                    type="textgroup"
                                    icon={{ back_icon: 'far fa-envelope' }}
                                    placeholder="이메일"
                                    defaultValue=""
                                    // disabled={loading}
                                    register={register({
                                        validate: {
                                            required,
                                            pattern: email,
                                        },
                                    })}
                                    errors={errors}
                                />
                            </div>
                            <div className="form-group">
                                {/* 사용자 이름 */}
                                <Input
                                    id="username"
                                    name="username"
                                    type="textgroup"
                                    icon={{ back_icon: 'far fa-user' }}
                                    placeholder="사용자 이름"
                                    defaultValue=""
                                    // disabled={loading}
                                    register={register({
                                        validate: {
                                            required,
                                            min: minLength(3),
                                            max: maxLength(12),
                                        },
                                    })}
                                    errors={errors}
                                />
                            </div>
                            <div className="form-group">
                                <Input
                                    id="password"
                                    name="password"
                                    type="passwordgroup"
                                    icon={{ back_icon: 'fas fa-lock' }}
                                    placeholder="비밀번호"
                                    defaultValue=""
                                    // disabled={loading}
                                    className="form-control"
                                    onChange={() => setValue('repassword', '')}
                                    register={register({
                                        validate: {
                                            required,
                                            pattern: password_pattern_1,
                                        },
                                    })}
                                    errors={errors}
                                />
                                {errors.password?.message && (
                                    <FormError errorMessage={errors.password?.message} name="password" />
                                )}
                            </div>
                            <div className="form-group">
                                <Input
                                    id="repassword"
                                    name="repassword"
                                    type="passwordgroup"
                                    icon={{ back_icon: 'fas fa-lock' }}
                                    placeholder="비밀번호 확인"
                                    defaultValue=""
                                    // disabled={loading}
                                    className="form-control"
                                    register={register({
                                        validate: {
                                            required,
                                            pattern: compare_password(getValues('password')),
                                        },
                                    })}
                                    errors={errors}
                                />
                                {errors.repassword?.message && (
                                    <FormError errorMessage={errors.repassword?.message} name="repassword" />
                                )}
                            </div>

                            <div className="row">
                                {/* <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div> */}
                                {/* <!-- /.col --> */}
                                <div className="col-12">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block mb-3"
                                        disabled={!isDirty || isSubmitting}
                                    >
                                        {/* {loading ? (
                                            <i className="fas fa-spinner fa-pulse"></i>
                                        ) : (
                                            '회원가입'
                                        )} */}
                                        회원가입
                                    </button>
                                </div>
                                {/* <!-- /.col --> */}
                            </div>
                        </form>

                        <p className="text-xs-center">
                            <Link to="/signin">로그인</Link>
                        </p>
                    </div>
                    {/* <!-- /.form-box --> */}
                </div>
                {/* <!-- /.card --> */}
            </div>
        </div>
    );
};

export default Signup;
