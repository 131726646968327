import { gql } from '@apollo/client';

// Tab 추가
export const DELETECOLLAPSE_MUTATION = gql`
    mutation deleteCollapseMutation($where: DeleteCollapseWhereDto!) {
        deleteCollapse(where: $where) {
            at_col_idx
        }
    }
`;
