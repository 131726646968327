import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Instance from '@/helpers/axiosInstance.helper';

const Contact_Detail: React.FC<any> = ({ data, onClose, id = '' }): JSX.Element => {
    return (
        <div
            className="modal fade"
            id={id}
            tabIndex={-1}
            aria-modal="true"
            role="dialog"
            aria-hidden="true"
            onClick={() => onClose()}
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h4 className="modal-title">
                            <i className="fas fa-globe" /> {data && data.company} 문의 내역
                        </h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            aria-hidden="true"
                            onClick={() => onClose()}
                        >
                            ×
                        </button>
                    </div>
                    <div className="modal-body">
                        {!data ? (
                            <>Loading...</>
                        ) : (
                            <div className="invoice  p-3 mb-0">
                                {/* <!-- title row --> */}
                                {/* <!-- info row --> */}
                                <div className="row invoice-info">
                                    <div className="col-sm-5 invoice-col">
                                        From
                                        <address>
                                            <b>회사명: </b>
                                            {data.company}
                                            <br />
                                            <b>담당자명: </b>
                                            {data.name}
                                            <br />
                                            <b>연락처: </b>
                                            {data.phone}
                                            <br />
                                            <b>Email: </b>
                                            {data.email}
                                        </address>
                                    </div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-sm-2 invoice-col"></div>
                                    {/* <!-- /.col --> */}
                                    <div className="col-sm-5 invoice-col">
                                        <br />
                                        <br />
                                        <br />
                                        <b>Order ID: </b>
                                        {data.pst_idx}
                                        <br />
                                        <b>Request Date: </b>
                                        {moment(data.pst_datetime).format('YYYY/MM/DD HH:mm')}
                                    </div>
                                    {/* <!-- /.col --> */}
                                </div>
                                {/* <!-- /.row --> */}

                                {/* <!-- Table row --> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="callout callout-info">
                                            <h5>
                                                <i className="fas fa-info"></i> 문의사항 :
                                            </h5>
                                            <p className="text-muted well well-sm shadow-none">{data.contents}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <!-- accepted payments column --> */}
                                    <div className="col-6">
                                        <p className="lead">첨부 파일</p>

                                        {_.size(data.file_list) <= 0 ? (
                                            <p>없음</p>
                                        ) : (
                                            _.map(data.file_list, (el, i) => {
                                                if (el === null) return <></>;
                                                return (
                                                    <a
                                                        key={i}
                                                        className="text-gray m-1"
                                                        onClick={() => {
                                                            const instance = new Instance();

                                                            instance.setOptions({
                                                                method: 'GET',
                                                                url: '/PDFdownloads/' + el.fil_filename, // + "?token=" + token,
                                                                responseType: 'blob',
                                                                headers: {
                                                                    ['Authorization']: 'Bearer ' + 'rsteam_admin',
                                                                    // ["Access-Control-Allow-Origin"]: "*",
                                                                    // ["Content-Type"]: "multipart/form-data",
                                                                    //["User-Agent"] : window.navigator.userAgent,
                                                                    //["Content-Length"] = 0,
                                                                },
                                                                // onDownloadProgress: async (e) => {
                                                                //     console.log(e);
                                                                // },
                                                            });

                                                            instance.request({
                                                                onComplete: (res) => {
                                                                    switch (res.status) {
                                                                        case 200:
                                                                        case 201:
                                                                            const url = window.URL.createObjectURL(
                                                                                new Blob([res.data]),
                                                                            );
                                                                            const link = document.createElement('a');
                                                                            link.href = url;
                                                                            link.setAttribute(
                                                                                'download',
                                                                                el.fil_originname,
                                                                            ); //or any other extension
                                                                            document.body.appendChild(link);
                                                                            link.click();
                                                                            break;
                                                                        default:
                                                                        //coconsole.log.data);
                                                                    }
                                                                },
                                                                onError: async (err) => {
                                                                    console.log(err);
                                                                    //_state.error = err;
                                                                    //setFiles([...files, _state]);
                                                                },
                                                                onFinally: async () => {
                                                                    //console.log("download Done");
                                                                },
                                                            });
                                                        }}
                                                        download={true}
                                                    >
                                                        <i className="fas fa-file fa-2x"></i>
                                                    </a>
                                                );
                                            })
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* <div className="modal-footer">
                        </div> */}
                </div>
            </div>
        </div>
    );
};
export default Contact_Detail;
