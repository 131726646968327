import { gql } from '@apollo/client';

// Tab 추가
export const GETCOLLAPSELIST_QUERY = gql`
    query getCollapseListQuery {
        getCollapseList {
            list {
                at_col_idx
                at_col_name
                at_col_sub_name
                at_collapse_contents {
                    at_coc_idx
                    at_col_idx
                    at_coc_name
                    at_coc_price
                    at_coc_date
                    at_coc_contents
                    at_coc_groups
                }
            }
        }
    }
`;
