import React from 'react';
import cx from 'classnames';

import { FormError } from '@/components/commons/Form-error';

const Text_Group: React.FC<any> = (props): JSX.Element => {
    const {
        register,
        id,
        name,
        placeholder = '',
        defaultValue,
        required = false,
        disabled = false,
        icon = null,
        errors = {},
    } = props;
    const error = !!errors[name]; // !!는 undefined, null, 0 를 Boolean 타입으로 묵시적 변환

    return (
        <div className="input-group">
            {icon?.front_icon && (
                <div className="input-group-prepend">
                    <span className="input-group-text">{/* <i className={icon.front_icon}></i> */}</span>
                </div>
            )}
            {/* input */}
            <input
                ref={register}
                id={id}
                name={name}
                type="text"
                placeholder={placeholder}
                defaultValue={defaultValue}
                required={required}
                disabled={disabled}
                className={cx('form-control', {
                    'is-invalid': error,
                })}
            />

            {/* icon */}
            {icon?.back_icon && (
                <div className="input-group-append">
                    <div className="input-group-text">
                        <i className={icon.back_icon}></i>
                    </div>
                </div>
            )}

            {/* error */}
            {errors[name]?.message && <FormError errorMessage={errors[name]?.message} name={name} />}
        </div>
    );
};
export default Text_Group;
