import { gql } from '@apollo/client';

// Tab 삭제
export const DELETETAB_MUTATION = gql`
    mutation deleteTabMutation($where: DeleteTabWhereDto!) {
        deleteTab(where: $where) {
            at_tab_idx
        }
    }
`;
