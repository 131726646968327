import { gql } from '@apollo/client';

export const SETTABCONTENTS_MUTATION = gql`
    mutation setTabContentsMutation($data: SetTabContentsDataDto!, $where: SetTabContentsWhereDto!) {
        setTabContents(data: $data, where: $where) {
            at_tc_idx
            at_tab_idx
            at_tc_contents
            at_tc_name
            at_tc_url
            at_tc_img
            at_tc_type
            at_tc_sub_type
        }
    }
`;
