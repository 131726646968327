import { authTokenVar, isLoggedInVar, levelVar } from '@/libraries/apollo';
import { LOCALSTORAGE_A_TOKEN, LOCALSTORAGE_LEVEL, LOCALSTORAGE_R_TOKEN } from '@/libraries/apollo/constants';

const Header = () => {
    const signout = () => {
        localStorage.removeItem(LOCALSTORAGE_A_TOKEN);
        localStorage.removeItem(LOCALSTORAGE_R_TOKEN);
        localStorage.removeItem(LOCALSTORAGE_LEVEL);
        isLoggedInVar(false);
        authTokenVar('');
        levelVar('');

        window.location.reload();
    };

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" role="button">
                        <i className="fas fa-bars"></i>
                    </a>
                </li>
            </ul>
            {!isLoggedInVar() && (
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item d-none d-sm-inline-block">
                        <a className="nav-link" href="/signin">
                            <i className="fas fa-user"></i>
                            로그인
                        </a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a className="nav-link" href="/signup">
                            <i className="fas fa-key"></i>
                            회원가입
                        </a>
                    </li>
                </ul>
            )}
            {isLoggedInVar() && (
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item d-none d-sm-inline-block">
                        <a className="nav-link" onClick={signout} style={{ cursor: 'pointer' }}>
                            <i className="fas fa-user"></i>
                            로그아웃
                        </a>
                    </li>
                </ul>
            )}
        </nav>
    );
};

export default Header;
