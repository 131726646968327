import { default_q_mark } from '../../../../include/Images';
import SearchBox from '@/components/commons/SearchBox';
import ControlBox from '@/components/commons/ControlBox';
import useList from '@/hooks/useList.hook';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { phoneDashFomatter, replaceFirstNumbersNotZero, numberWithCommas } from '@/helpers/common.helper';

import { GETCOLLAPSELIST_QUERY } from '@/querys/admin/collapse/getCollapseList';
import { ADDCOLLAPSE_MUTATION } from '@/querys/admin/collapse/addCollapse';
import { ADDCOLLAPSECONTENTS_MUTATION } from '@/querys/admin/collapse/addCollapseContents';
import { SETCOLLAPSE_MUTATION } from '@/querys/admin/collapse/setCollapse';
import { SETCOLLAPSECONTENTS_MUTATION } from '@/querys/admin/collapse/setCollapseContents';
import { DELETECOLLAPSE_MUTATION } from '@/querys/admin/collapse/deleteCollapse';
import { DELETECOLLAPSECONTENTS_MUTATION } from '@/querys/admin/collapse/deleteCollapseContents';
/**
 * 추천 기능 그룹 graphql Qurey
 */
import { GETCOLLAPSELISTGROUPSLIST_QUERY } from '@/querys/admin/collapse/getCollapseContentsGroupsList';
import { ADDCOLLAPSECONTENTSGROUPS_MUTATION } from '@/querys/admin/collapse/addCollapseContentsGroups';
import { SETCOLLAPSECONTENTSGROUPS_MUTATION } from '@/querys/admin/collapse/setCollapseContentsGroups';
import { DELETECOLLAPSECONTENTSGROUPS_MUTATION } from '@/querys/admin/collapse/deleteCollapseContentsGroups';
import { group } from 'console';

/**
 * 추천 기능 그룹 *********************************************************************
 **/

const CollapseContentsGroups = ({
    item,
    setContentsGroups = (idx, name, description) => console.log(idx, name, description),
    delContentsGroups = (idx) => console.log(idx),
}) => {
    const ccgNameInput = useRef(null);
    const ccgDecriptionInput = useRef(null);
    return (
        <tr>
            <td className="p-2">
                <div className="input-group input-group-sm mb-2">
                    <input
                        ref={ccgNameInput}
                        className={'form-control'}
                        type="text"
                        defaultValue={item.at_ccg_name}
                        placeholder="추천 기능 그룹 이름"
                    />

                    <div className="input-group-append">
                        {/* Save Tab Name */}
                        <button
                            type="button"
                            className="btn btn-default"
                            title="추천 기능 그룹 이름 저장"
                            onClick={async () => {
                                setContentsGroups(
                                    item.at_ccg_idx,
                                    ccgNameInput.current.value,
                                    ccgDecriptionInput.current.value,
                                );
                            }}
                        >
                            <i className={'fas fa-save'} />
                        </button>
                        {/* Delete */}
                        <button
                            type="button"
                            className="btn btn-default"
                            title="삭제"
                            onClick={() => {
                                if (!window.confirm('해당 추천 기능 그룹을 삭제하시겠습니까?')) return;
                                delContentsGroups(item.at_ccg_idx);
                            }}
                        >
                            <i className="fas fa-trash" />
                        </button>
                    </div>
                </div>
                <textarea
                    ref={ccgDecriptionInput}
                    className={'form-control'}
                    defaultValue={item.at_ccg_description}
                    placeholder="추천 기능 그룹 설명"
                ></textarea>
            </td>
        </tr>
    );
};

/**
 * 기능 수정 폼 *********************************************************************
 **/
const Item = ({
    item,
    groups,
    setFunction = (idx, name, date, price, contents, groups) => console.log(idx, name, date, price, contents, groups),
    delFunction = (data) => console.log(data),
}) => {
    const cocNameInput = useRef(null);
    const cocDateInput = useRef(null);
    const cocPriceInput = useRef(null);
    const cocDescriptionInput = useRef(null);

    const [groupState, setGroupState] = useState(item.at_coc_groups === null ? [] : JSON.parse(item.at_coc_groups));
    return (
        <div className="post">
            <div className="user-block">
                <div className="row">
                    <div className="col-md-12">
                        <div className="input-group input-group-sm mb-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text">기능명</span>
                            </div>
                            <input
                                ref={cocNameInput}
                                className="form-control"
                                type="text"
                                defaultValue={item.at_coc_name}
                                placeholder="기능명"
                            />
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    title="기능 저장"
                                    onClick={() => {
                                        if (!window.confirm('저장하시겠습니까?')) return;
                                        setFunction(
                                            item.at_coc_idx,
                                            cocNameInput.current.value,
                                            cocDateInput.current.value,
                                            cocPriceInput.current.value,
                                            cocDescriptionInput.current.value,
                                            groupState,
                                        );
                                    }}
                                >
                                    <i className="fas fa-save" />
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    title="기능 삭제"
                                    onClick={() => {
                                        if (!window.confirm('해당 기능을 삭제하시겠습니까?')) return;
                                        delFunction(item.at_coc_idx);
                                    }}
                                >
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="input-group input-group-sm mb-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text">기능금액</span>
                            </div>
                            <input
                                min={0}
                                max={99999999}
                                ref={cocPriceInput}
                                className="form-control text-right"
                                type="number"
                                placeholder="기능 가격 (원)"
                                defaultValue={item.at_coc_price}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">원</span>
                            </div>
                        </div>
                        <div className="input-group input-group-sm">
                            <div className="input-group-prepend">
                                <span className="input-group-text">개발기간</span>
                            </div>
                            <input
                                min={0}
                                max={120}
                                ref={cocDateInput}
                                className="form-control text-right"
                                type="number"
                                placeholder="개발 기간 (일)"
                                defaultValue={item.at_coc_date}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">일</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <textarea
                            ref={cocDescriptionInput}
                            className="form-control form-control-sm"
                            rows={3}
                            placeholder="기능 설명"
                            defaultValue={item.at_coc_contents}
                        />
                    </div>
                </div>
            </div>
            {/* 추천 그룹 체크박스 */}
            <label>추천 그룹 설정</label>
            <div className="info-box">
                <div className="row mb-3">
                    {_.map(groups, (el, i) => {
                        return (
                            <div className="col-md-3" key={'groupidx_' + el.data.at_ccg_idx}>
                                <input
                                    className="mr-2"
                                    type="checkbox"
                                    defaultChecked={_.find(
                                        groupState,
                                        (group_idx, i) => group_idx === el.data.at_ccg_idx,
                                    )}
                                    onChange={(e) => {
                                        const arr = groupState;
                                        if (e.currentTarget.checked) {
                                            arr.push(el.data.at_ccg_idx);
                                        } else {
                                            const idx = arr.indexOf(el.data.at_ccg_idx);
                                            if (idx > -1) arr.splice(idx, 1);
                                        }
                                        setGroupState(arr);
                                    }}
                                />
                                <span>{el.data.at_ccg_name}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

/**
 * 기능 그룹 폼 *********************************************************************
 **/
const Collapse = ({
    item,
    groups,
    setTab = (data, name, sub_name) => console.log(data, name, sub_name),
    delTab = (data) => console.log(data),
    setFunction = (idx, name, date, price, contents, groups) => console.log(idx, name, date, price, contents, groups),
    addFunction = (data) => console.log(data),
    delFunction = (data) => console.log(data),
}) => {
    const [open, setOpen] = useState(false);
    const colNameInput = useRef(null);
    const colSubNameInput = useRef(null);
    return (
        <div className="card card-purple card-outline collapsed-card m-2 mb-3">
            {/*style={{ backgroundColor: '#655ba8' }}*/}
            <div className="card-header">
                <h3 className="card-title col-11">
                    <div className="input-group">
                        <input
                            ref={colNameInput}
                            className={'form-control'}
                            type="text"
                            defaultValue={item.at_col_name}
                            placeholder="기능 분류 이름"
                        />
                        <input
                            ref={colSubNameInput}
                            className={'form-control'}
                            type="text"
                            defaultValue={item.at_col_sub_name}
                            placeholder="기능 분류 설명"
                        />
                        <div className="input-group-append">
                            {/* Save Tab Name */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="기능 분류 이름 저장"
                                onClick={async () => {
                                    setTab(item.at_col_idx, colNameInput.current.value, colSubNameInput.current.value);
                                }}
                            >
                                <i className={'fas fa-save'} />
                            </button>
                            {/* Add Item */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="기능 분류 추가"
                                onClick={async () => {
                                    if (!window.confirm('새로운 기능 분류를 생성 하시겠습니까?')) return;
                                    addFunction(item.at_col_idx);
                                }}
                            >
                                <i className={'fas fa-plus'} />
                            </button>
                            {/* Delete */}
                            <button
                                type="button"
                                className="btn btn-default"
                                title="삭제"
                                onClick={() => {
                                    if (
                                        !window.confirm(
                                            '해당 기능 분류를 전부 삭제하시겠습니까?\n기능 카테고리에 포함된 모든 기능가 모두 삭제됩니다.',
                                        )
                                    )
                                        return;
                                    delTab(item.at_col_idx);
                                }}
                            >
                                <i className="fas fa-trash" />
                            </button>
                        </div>
                    </div>
                </h3>
                <div className="card-tools">
                    {/* Collapse */}
                    <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                        onClick={() => setOpen(!open)}
                    >
                        <i className={open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />
                    </button>
                </div>
            </div>
            <div className="card-body p-3">
                {/* 세부 기능들 *********************************************** */}
                {_.map(item.at_collapse_contents, (func, i) => (
                    <Item
                        key={'func' + i}
                        groups={groups}
                        item={func}
                        setFunction={setFunction}
                        delFunction={delFunction}
                    />
                ))}
            </div>
        </div>
    );
};

/**
 * 기능 그룹 리스트  ********************************************************************************
 */
const FunctionList: React.FC = (): JSX.Element => {
    const [
        state,
        {
            getCheckItems,
            setSearch,
            onChoice,
            onResetChoice,
            onChecked,
            onCheckedAll,
            onReset,
            onUpdate,
            onDelete,
            onAdd,
        },
        { pages, movePage },
    ] = useList({});
    const [stategroups, funcGroups, funcPages] = useList({});

    /**
     * 추천 기능 그룹 쿼리들
     */
    const [getCollapseContentsGroupsList] = useLazyQuery(GETCOLLAPSELISTGROUPSLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { list = [] } = data?.getCollapseContentsGroupsList;
            const count = list.length;
            funcGroups.onReset(list, count);
        },
        onError: (err) => console.error(err),
    });

    const [addCollapseContentsGroups] = useMutation(ADDCOLLAPSECONTENTSGROUPS_MUTATION, {
        onError: (err) => console.error(err),
    });

    const [setCollapseContentsGroups] = useMutation(SETCOLLAPSECONTENTSGROUPS_MUTATION, {
        onError: (err) => console.error(err),
    });

    const [delCollapseContentsGroups] = useMutation(DELETECOLLAPSECONTENTSGROUPS_MUTATION, {
        onError: (err) => console.error(err),
    });

    /**
     * 그룹별 기능 목록 가져오기 쿼리
     */
    // get Tab/Contents
    const [getCollapseList] = useLazyQuery(GETCOLLAPSELIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { list = [] } = data?.getCollapseList;
            const count = list.length;
            onReset(list, count);
        },
        onError: (err) => console.error(err),
    });

    // add Items
    const [addCollapse] = useMutation(ADDCOLLAPSE_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [addCollapseItem] = useMutation(ADDCOLLAPSECONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    // set Tab/Contents Items
    const [setCollapse] = useMutation(SETCOLLAPSE_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [setCollapseItem] = useMutation(SETCOLLAPSECONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    // delete Items
    const [delCollapse] = useMutation(DELETECOLLAPSE_MUTATION, {
        onError: (err) => console.error(err),
    });
    const [delCollapseItem] = useMutation(DELETECOLLAPSECONTENTS_MUTATION, {
        onError: (err) => console.error(err),
    });

    const graphql_data = {
        variables: {
            where: {
                at_col_page: 'qna',
            },
        },
    };

    /**
     * 초기 1회 실행
     */
    useEffect(() => {
        getCollapseContentsGroupsList();
        getCollapseList();
    }, []);

    // 검색 기능 => "Tab Name(제목)"
    const handleSearch = async (search) => {
        setSearch(search);
        if (search.word.length > 0) {
            graphql_data.variables.where['at_col_name'] = search.word;
        } else {
            delete graphql_data.variables.where['at_col_name'];
        }
        await getCollapseList();
    };

    // 껍데기 부분
    return (
        <div className="invoice m-0 p-0">
            <div className="row">
                <div className="col-3">
                    <div className="card-header">
                        <h3 className="card-title">추천기능 그룹</h3>
                        {/* <div className="card-tools">
                                <SearchBox
                                    inSearch={false}
                                    onSearch={handleSearch}
                                    placeholder="제목"
                                />
                            </div> */}
                    </div>

                    <div className="card-body p-0">
                        {/* 추천 기능 그룹 리스트 */}
                        <ControlBox
                            onRefresh={() => {
                                getCollapseContentsGroupsList();
                            }}
                            onAdd={async () => {
                                const res = await addCollapseContentsGroups({
                                    variables: {
                                        data: {
                                            at_ccg_name: '새로운 추천 기능 그룹',
                                            at_ccg_description: '추천 기능 그룹 설명',
                                        },
                                    },
                                });

                                if (res?.errors) {
                                    alert('추천 기능 그룹 생성에 실패했습니다.');
                                    return;
                                }

                                getCollapseContentsGroupsList();
                            }}
                        />
                        <table className="table table-striped table-hover">
                            <thead>
                                {_.map(stategroups.view_list, (el, i) => {
                                    return (
                                        <CollapseContentsGroups
                                            key={'cfuncgroups_' + i}
                                            item={el.data}
                                            setContentsGroups={async (ccg_idx, group_name, group_description) => {
                                                const res = await setCollapseContentsGroups({
                                                    variables: {
                                                        data: {
                                                            at_ccg_name: group_name,
                                                            at_ccg_description: group_description,
                                                        },
                                                        where: {
                                                            at_ccg_idx: ccg_idx,
                                                        },
                                                    },
                                                });
                                                if (res?.errors) {
                                                    alert('추천 기능 그룹 정보 수정에 실패했습니다.');
                                                    return;
                                                }
                                                if (res?.data) {
                                                    alert('추천 기능 그룹 정보를 수정했습니다.');
                                                    getCollapseContentsGroupsList();
                                                    return;
                                                }
                                            }}
                                            delContentsGroups={async (groups_idx) => {
                                                const res = await delCollapseContentsGroups({
                                                    variables: {
                                                        where: {
                                                            at_ccg_idx: [groups_idx],
                                                        },
                                                    },
                                                });
                                                if (res?.errors) {
                                                    alert('삭제에 실패했습니다.');
                                                    return;
                                                }
                                                funcGroups.onDelete(null, el.id);
                                            }}
                                        />
                                    );
                                })}
                            </thead>
                        </table>
                    </div>
                </div>
                {/* 그룹별 기능 리스트 */}
                <div className="col-9">
                    <div className="card-header">
                        <h3 className="card-title">{'기능 그룹 > 기능'}</h3>
                        {/* <div className="card-tools">
                                    <SearchBox
                                        inSearch={false}
                                        onSearch={handleSearch}
                                        placeholder="제목"
                                    />
                                </div> */}
                    </div>

                    <div className="card-body p-0">
                        <ControlBox
                            onRefresh={() => {
                                getCollapseList();
                            }}
                            onAdd={async () => {
                                let res = await addCollapse({
                                    variables: {
                                        data: {
                                            at_col_name: '새로운 기능 분류',
                                            at_col_sub_name: '기능 분류 설명',
                                        },
                                    },
                                });

                                if (res?.errors) {
                                    alert('기능 분류 생성에 실패했습니다.');
                                    return;
                                }
                                res = await addCollapseItem({
                                    variables: {
                                        data: {
                                            at_col_idx: res.data.addCollapse.at_col_idx,
                                            at_coc_name: '새로운 기능',
                                            at_coc_date: 12,
                                            at_coc_price: 0,
                                            at_coc_contents: '기능 수정이 필요합니다.',
                                            at_coc_groups: JSON.stringify([]),
                                        },
                                    },
                                });

                                if (res?.errors) {
                                    alert('기능 생성에 실패했습니다.');
                                    return;
                                }

                                getCollapseList();
                            }}
                        />
                        {_.map(state.view_list, (el, i) => {
                            return (
                                <Collapse
                                    key={'cfunc_' + i}
                                    groups={stategroups.view_list}
                                    item={el.data}
                                    setTab={async (col_idx, tab_name) => {
                                        const res = await setCollapse({
                                            variables: {
                                                data: {
                                                    at_col_name: tab_name,
                                                    at_col_sub_name: tab_name,
                                                },
                                                where: {
                                                    at_col_idx: col_idx,
                                                },
                                            },
                                        });
                                        if (res?.errors) {
                                            alert('이름 수정에 실패했습니다.');
                                            return;
                                        }
                                        if (res?.data) {
                                            alert('이름을 수정했습니다.');
                                            return;
                                        }
                                    }}
                                    delTab={async (col_idx) => {
                                        const res = await delCollapse({
                                            variables: {
                                                where: {
                                                    at_col_idx: col_idx, //tabObj.at_col_idx,
                                                },
                                            },
                                        });
                                        if (res?.errors) {
                                            alert('삭제에 실패했습니다.');
                                            return;
                                        }
                                        onDelete(null, el.id);
                                        //getCollapseList();
                                    }}
                                    addFunction={async (col_idx) => {
                                        const res = await addCollapseItem({
                                            variables: {
                                                data: {
                                                    at_col_idx: col_idx,
                                                    at_coc_name: '새로운 기능',
                                                    at_coc_date: 12,
                                                    at_coc_price: 0,
                                                    at_coc_contents: '기능 수정이 필요합니다.',
                                                    at_coc_groups: JSON.stringify([]),
                                                },
                                            },
                                        });
                                        if (res?.errors) {
                                            alert('추가에 실패했습니다.');
                                            return;
                                        }
                                        getCollapseList();
                                    }}
                                    setFunction={async (
                                        coc_idx,
                                        coc_name,
                                        coc_date,
                                        coc_price,
                                        coc_contents,
                                        coc_groups,
                                    ) => {
                                        //db at_coc_price data type은 int이며 int 최대치는 2^31-1 이다.
                                        //이 수치가 넘어가면 DB에 저장 안됨
                                        if (Math.pow(2, 31) - 1 < coc_price) {
                                            coc_price = Math.pow(2, 31) - 1;
                                        }

                                        setCollapseItem({
                                            variables: {
                                                data: {
                                                    at_col_idx: el.data.at_col_idx,
                                                    at_coc_name: coc_name,
                                                    at_coc_date: Math.abs(Number(coc_date)),
                                                    at_coc_price: Math.abs(Number(coc_price)),
                                                    at_coc_contents: coc_contents,
                                                    at_coc_groups: JSON.stringify(coc_groups),
                                                },
                                                where: { at_coc_idx: coc_idx },
                                            },
                                        });
                                    }}
                                    delFunction={async (tc_idx) => {
                                        const res = await delCollapseItem({
                                            variables: {
                                                where: {
                                                    at_coc_idx: [tc_idx],
                                                },
                                            },
                                        });
                                        if (res?.errors) {
                                            alert('삭제에 실패했습니다.');
                                            return;
                                        }
                                        getCollapseList();
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FunctionList;
