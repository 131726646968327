import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { logo_athenes, logo_rsteam } from '../../include/Images';

const Main = (props) => {
    //update setInterval start

    return (
        <>
            <Helmet>
                <title>RS | Main</title>
            </Helmet>
            <div className="content">
                <div className="row justify-content-center align-items-center p-5">
                    <div className="col-12 col-sm-6 col-md-2 d-flex align-items-stretch flex-column">
                        {/* RS-Team Card */}
                        <Link className="card card-primary card-outline d-flex flex-fill" to="/website/portfolio">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src={logo_rsteam}
                                        alt="User profile picture"
                                    />
                                </div>

                                <h3 className="profile-username text-center">RS-Team Website</h3>

                                <p className="text-muted text-center">알에스팀 메인 홈페이지 {props.runtime}</p>
                                <button
                                    className="btn btn-primary btn-block"
                                    onClick={() => window.open('https://www.rs-team.com/', '_blank')}
                                >
                                    <b>웹사이트 바로가기</b>
                                </button>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 col-sm-6 col-md-2 d-flex align-items-stretch flex-column">
                        {/* Athenes Card */}
                        <Link className="card card-primary card-outline" to="/athenes/function">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img
                                        className="profile-user-img img-fluid img-circle"
                                        src={logo_athenes}
                                        alt="User profile picture"
                                        width={200}
                                        height={200}
                                    />
                                </div>

                                <h3 className="profile-username text-center">Athenes Website</h3>

                                <p className="text-muted text-center">웹사이트 개발 서비스</p>

                                {/* <ul className="list-group list-group-unbordered mb-3">
                                        <li className="list-group-item">
                                            <b>Followers</b>{' '}
                                            <a className="float-right">1,322</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Following</b>{' '}
                                            <a className="float-right">543</a>
                                        </li>
                                        <li className="list-group-item">
                                            <b>Contrat us</b>{' '}
                                            <a className="float-right">
                                                13,287
                                            </a>
                                        </li>
                                    </ul> */}

                                <button
                                    className="btn btn-primary btn-block"
                                    onClick={() => window.open('https://athenes.io/', '_blank')}
                                >
                                    <b>웹사이트 바로가기</b>
                                </button>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Main;
