import { gql } from '@apollo/client';
export const SETPOST_MUTATION = gql`
    mutation setPostMutation($where: SetPostWhereDto!, $data: SetPostDataDto!) {
        setPost(where: $where, data: $data) {
            post {
                pst_idx
                pst_brd_idx
                pst_bca_idx
                pst_mem_idx
                pst_mem_email
                pst_mem_nickname
                pst_secret
                pst_blind
                pst_notice
                pst_deletion
                pst_title
                pst_content
                pst_thumbnail
                pst_datetime
                pst_updated_datetime
                pst_deletion_datetime
                pst_hit
                pst_ip
                pst_report
                pst_like
                pst_dislike
                files {
                    fil_idx
                    fil_filename
                    fil_originname
                    fil_path
                    fil_size
                    fil_mimetype
                }
            }
        }
    }
`;
