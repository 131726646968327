import { createAction, handleActions } from 'redux-actions';
import { applyPenders } from 'redux-pender';

/** ***************** ACTIONS  ****************** */
export const THEME = 'GLOBAL_STATUS/THEME';
export const LANGUAGE = 'GLOBAL_STATUS/LANGUAGE';
export const SERVER = 'GLOBAL_STATUS/SERVER';
export const MOBILE = 'GLOBAL_STATUS/MOBILE';
export const ORIENTATION = 'GLOBAL_STATUS/ORIENTATION';
export const INCREMENT_RUNTIME = 'GLOBAL_STATUS/INCREMENT_RUNTIME';

/** ***************** ACTION FUNCTIONS ****************** */
export const pingServer = createAction(SERVER);
export const incrementRuntime = createAction(INCREMENT_RUNTIME, (aaa) => aaa);
export const setLanguage = createAction(LANGUAGE);
export const isMobile = createAction(MOBILE);
export const isOrientation = createAction(ORIENTATION);

const initialState = {
    theme: 'default',
    language: navigator.language,
    server: null,
    mobile: Boolean(navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)),
    orientation: !navigator.maxTouchPoints ? 'desktop' : !window.orientation ? 'portrait' : 'landscape',
    runtime: 0,
};

const reducer = handleActions(
    {
        [SERVER]: (state, action) => ({
            ...state,
            server: action.payload.data,
        }),
        [LANGUAGE]: (state, action) => ({
            ...state,
            language: action.payload,
        }),
        [MOBILE]: (state) => ({
            ...state,
            mobile: Boolean(
                navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
            ),
        }),
        [ORIENTATION]: (state) => ({
            ...state,
            orientation: !navigator.maxTouchPoints ? 'desktop' : !window.orientation ? 'portrait' : 'landscape',
        }),
        [INCREMENT_RUNTIME]: (state) => ({
            ...state,
            runtime: state.runtime + 1,
        }),
    },
    initialState,
);

export default applyPenders(reducer, [
    {
        type: SERVER,
        // onPending: (state, action) => {return state},
        onSuccess: (state, action) => ({
            ...state,
            server: action.payload.data,
        }),
        onFailure: (state, action) => {
            console.log('onFailure', action.payload);
            return {
                ...state,
                server: null,
            };
        },
    },
]);
