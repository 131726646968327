import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { htmlspecialchars_decode } from 'locutus/php/strings';
import moment from 'moment';
import useList from '@/hooks/useList.hook';

/**
 * Import Common Components
 */
import SearchBox from '@/components/commons/SearchBox';
import ControlBox from '@/components/commons/ControlBox';
import Pagination from '@/components/commons/Pagination_0';

import Notice_detail from './notice_detail';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GETPOSTLIST_QUERY } from '@/querys/board/post/getPostList';
import { DELETEPOSTMANY_MUTATION } from '@/querys/board/post/deletePostMany';
import { GETPOSTONE_QUERY } from '@/querys/board/post/getPostOne';
import { SETPOST_MUTATION } from '@/querys/board/post/setPost';
import { ADDPOST_MUTATION } from '@/querys/board/post/addPost';
import {
    addPostMutation,
    addPostMutationVariables,
    deletePostManyMutation,
    deletePostManyMutationVariables,
    setPostMutation,
    setPostMutationVariables,
} from '@/interfaces/apollo.interface';

const Notice: React.FC = (): JSX.Element => {
    // 리스트 훅
    const [
        state,
        {
            getCheckItems,
            setSearch,
            onChoice,
            onResetChoice,
            onChecked,
            onCheckedAll,
            onReset,
            onUpdate,
            onDelete,
            onAdd,
        },
        { pages, movePage },
    ] = useList({});

    const dialogId = 'rsNoticeModalCenter';
    const dialogModalRef = useRef(null);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [viewDetial, setViewDetial] = useState(null);
    const [graphql_data, setGraphql_data] = useState({
        variables: {
            skip: pages.pos_current * pages.cnt_print,
            take: pages.cnt_print,
            where: {
                brd_key: 'notice',
            },
        },
    });

    const [getList, { data, loading, error }] = useLazyQuery(GETPOSTLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            console.log('notice refeash');
            const { list = [], count = 0 } = data?.getPostList?.post;
            onReset(list, count);
        },
    });

    const [getItem] = useLazyQuery(GETPOSTONE_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            setViewDetial(data.getPostOne.post);
        },
    });

    // 글 삭제기능
    const [deletePostManyMutation, { data: Udata }] = useMutation<
        deletePostManyMutation,
        deletePostManyMutationVariables
    >(DELETEPOSTMANY_MUTATION, {
        onCompleted: (data) => {},
        onError: (error) => {
            alert('삭제에 실패했습니다.');
        },
    });

    // 글 수정
    const [setPostMutation, { data: setData }] = useMutation<setPostMutation, setPostMutationVariables>(
        SETPOST_MUTATION,
        {
            onCompleted: (data) => {
                alert('수정 되었습니다.');
                getList(graphql_data);
                setViewDetial(false);

                onResetChoice();
                dialogModalRef.current.click();
            },
            onError: (error) => {
                alert('수정에 실패했습니다.');
                getList(graphql_data);
                onResetChoice();
                dialogModalRef.current.click();
            },
        },
    );

    const [addPostMutation, { data: addData }] = useMutation<addPostMutation, addPostMutationVariables>(
        ADDPOST_MUTATION,
        {
            onCompleted: (data) => {
                getList(graphql_data);
                setViewDetial(false);
            },
            onError: (error) => {
                alert('추가에 실패했습니다.');
            },
        },
    );

    /**
     * 초기 1회 실행
     */
    useEffect(() => {
        getList(graphql_data);
    }, []);

    //검색기능
    const handleSearch = async (search) => {
        if (search.word.length > 0) {
            if (!(search.word.length > 1 && search.word.length <= 50)) {
                window.alert('검색어는 2~50자 까지입니다.');
                return;
            }
        }

        await setSearch(search);

        const _graphql_data = graphql_data;
        if (search.word.length > 0) {
            _graphql_data.variables.where['pst_title'] = search.word;
        } else {
            delete _graphql_data.variables.where['pst_title'];
        }
        _graphql_data.variables.skip = 0 * pages.cnt_print;
        _graphql_data.variables.take = pages.cnt_print;
        setGraphql_data(_graphql_data);
        movePage(0);
        await getList(graphql_data);
    };

    const handlePage = async (currentPage) => {
        await movePage(currentPage);

        const _graphql_data = graphql_data;
        _graphql_data.variables.skip = currentPage * pages.cnt_print;
        _graphql_data.variables.take = pages.cnt_print;
        setGraphql_data(_graphql_data);
        await getList(graphql_data);
    };

    const handleSubmit = async (formData, pst_idx, file_idx) => {
        const { title, contents } = formData;
        const _file_idx = file_idx === null ? [] : [file_idx];
        if (!pst_idx) {
            addPostMutation({
                variables: {
                    where: {
                        brd_key: 'notice',
                    },
                    data: {
                        title: title,
                        contents: contents,
                        // name: 'rs-team',
                        nonmember: {
                            nonmember_email: 'admin@ts-tema.com',
                            nonmember_nickname: 'rs-team.com',
                            nonmember_password: String(Math.floor(Math.random() * Math.pow(10, 6))), // 6자리 난수를 비밀번호로
                        },
                        uploads: _file_idx,
                    },
                    option: {
                        is_secret: true,
                    },
                },
            });
            return;
        }
        setPostMutation({
            variables: {
                where: {
                    pst_idx: pst_idx,
                },
                data: {
                    title: title,
                    contents: contents,
                    uploads: _file_idx,
                },
            },
        });
    };

    if (error) {
        return (
            <>
                <h1>Error!</h1>
            </>
        );
    }

    return (
        <>
            {/* 디테일 창 여는 버튼 */}
            <button
                ref={dialogModalRef}
                style={{ display: 'none' }}
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target={'#' + dialogId}
            >
                dialogModaToggle
            </button>
            <Notice_detail
                id={dialogId}
                data={viewDetial}
                onClose={() => {
                    //setViewDetial(null);
                    onResetChoice();
                }}
                onSubmit={handleSubmit}
                loading={loading}
            />
            {/* {viewDetial && <Contact_Detail data={viewDetial} onClose={() => setViewDetial(null)} />} */}

            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">RS-Team Notice</h3>
                    <div className="card-tools">
                        <SearchBox inSearch={false} onSearch={handleSearch} placeholder="제목 검색" />
                    </div>
                </div>
                <div className="card-body p-0">
                    <ControlBox
                        // onCheckAll={(check) => {
                        //     onCheckedAll(check);
                        //     console.log('cb');
                        // }}
                        onRemove={async () => {
                            if (!confirm(`모든 항목을 삭제하시겠습니까?`)) return;
                            const delList = getCheckItems();
                            const pst_idxs = _.map(delList, (el) => el.data.pst_idx);
                            //비동기 처리
                            const result = await deletePostManyMutation({
                                variables: {
                                    where: {
                                        pst_idx: pst_idxs,
                                    },
                                },
                            });
                            if (result?.errors) {
                                alert('삭제에 실패했습니다.');
                                return;
                            }
                            //_.forEach(delList, (el, i) => onDelete(null, i));
                            //성공시 onDelete
                            getList(graphql_data);
                        }}
                        onRefresh={() => {
                            getList(graphql_data);
                        }}
                        onAdd={() => {
                            addPostMutation({
                                variables: {
                                    where: {
                                        brd_key: 'notice',
                                    },
                                    data: {
                                        title: 'New Note',
                                        contents: 'Description',
                                        // name: 'rs-team',
                                        nonmember: {
                                            nonmember_email: 'admin@ts-tema.com',
                                            nonmember_nickname: 'rs-team.com',
                                            nonmember_password: String(Math.floor(Math.random() * Math.pow(10, 6))), // 6자리 난수를 비밀번호로
                                        },
                                        uploads: [],
                                    },
                                    option: {
                                        is_secret: true,
                                    },
                                },
                            });
                            setViewDetial(true);
                        }}
                    />
                    <table className="table table-striped table-hover text-nowrap">
                        <colgroup>
                            <col width={'20px'} />
                            <col width={'30px'} />
                            <col width={'20%'} />
                            <col width={'*'} />
                            <col width={'16%'} />
                            <col width={'25px'} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div className="icheck-primary d-inline">
                                        <input
                                            id="checkboxAll_cnt_notice"
                                            type="checkbox"
                                            defaultChecked={checkAll}
                                            //checked={checkAll}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onCheckedAll(!checkAll);
                                                setCheckAll(!checkAll);
                                            }}
                                        />
                                        <label htmlFor={'checkboxAll_cnt_notice'}></label>
                                    </div>
                                </th>
                                <th>#</th>
                                <th>Email</th>
                                <th>Title</th>
                                <th>Date</th>
                                <th>
                                    <i className={'fas fa-trash'} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(state.view_list, (el, i) => {
                                return (
                                    <tr
                                        key={'notice_' + i}
                                        className={el.active ? 'bg-primary disabled' : ''}
                                        id={i}
                                        onClick={(e) => {
                                            const _data = onChoice(e);
                                            setViewDetial(null);
                                            getItem({
                                                variables: {
                                                    where: {
                                                        pst_idx: _data.data.pst_idx,
                                                    },
                                                    option: {
                                                        is_view: true,
                                                    },
                                                },
                                            });
                                            dialogModalRef.current.click();
                                        }}
                                    >
                                        <td>
                                            <div
                                                className="icheck-primary d-inline"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <input
                                                    id={'notichk_' + i}
                                                    name={i}
                                                    type="checkbox"
                                                    checked={el.checked}
                                                    onChange={onChecked}
                                                />
                                                <label htmlFor={'notichk_' + i}></label>
                                            </div>
                                        </td>
                                        <td>{el.data.pst_idx}</td>
                                        <td>{el.data.pst_mem_email}</td>
                                        <td>{htmlspecialchars_decode(el.data.pst_title)}</td>
                                        <td>{moment(el.data.pst_datetime).format('YYYY-MM-DD')}</td>
                                        <td>
                                            <button
                                                id={i}
                                                type="button"
                                                className="btn btn-block bg-gradient-danger btn-xs"
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    if (!confirm(`${el.data.pst_idx}번 항목을 삭제하시겠습니까?`))
                                                        return;

                                                    const res = await deletePostManyMutation({
                                                        variables: {
                                                            where: {
                                                                pst_idx: el.data.pst_idx,
                                                            },
                                                        },
                                                    });
                                                    if (res?.errors) {
                                                        alert('삭제에 실패했습니다.');
                                                        return;
                                                    }
                                                    // if (res?.data?.deletePostMany?.pst_idx[0] === el.data.pst_idx) {
                                                    //     onDelete(e, el.id);
                                                    // }
                                                    getList(graphql_data);
                                                }}
                                            >
                                                <i className={'fas fa-trash m-1'} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination className="m-3" pages={pages} onMovePage={(pageNumber) => handlePage(pageNumber)} />
            </div>
        </>
    );
};
export default Notice;
