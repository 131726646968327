import React from 'react';
import cx from 'classnames';
import { humanFileSize } from '@/helpers/common.helper';

import Progress from './Progress';
import { isNull } from 'lodash';

const ItemCard = ({
    data,
    onRemove = (e, idx) => {
        console.log(e, idx);
    },
    onDownload = (e, filename) => {
        console.log(e, filename);
    },
}) => {
    let fileTypeIcon = <i className="far fa-file" />;
    let imgFlag = false;
    let fontAsomeFileTypeIcon2 = 'fas fa-paperclip';

    const { uid, file, resData, error, perProgress, isUploading, isFinish } = data;

    const mimetype = resData?.fil_mimetype || file?.mimetype;
    const filename = resData?.fil_filename || file?.name;
    //console.log(resData);
    //file HTTP protocal MIME Type
    switch (mimetype) {
        // Audio
        case 'application/ogg':
        case 'audio/aac':
        case 'audio/midi audio/x-midi':
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/opus':
        case 'audio/wav':
        case 'audio/webm': {
            fileTypeIcon = <i className="far fa-audio" />;
            break;
        }

        //video
        case 'video/3gpp':
        case 'video/3gpp2':
        case 'video/mp2t':
        case 'video/mpeg':
        case 'video/ogg':
        case 'video/webm':
        case 'video/x-msvideo': {
            fileTypeIcon = <i className="far fa-video" />;
            break;
        }

        //images
        case 'image/bmp':
        case 'image/gif':
        case 'image/jpeg':
        case 'image/png':
        case 'image/svg+xml':
        case 'image/tiff':
        case 'image/vnd.microsoft.icon':
        case 'image/webp': {
            fileTypeIcon = <i className="far fa-image" />;

            //서버에 업로드이미지 파일은 get으로 인증없이 데이터를 가
            // fileTypeIcon = (
            //     <img src={URL.createObjectURL(file)} alt="Attachment" />
            // );
            imgFlag = true;
            fontAsomeFileTypeIcon2 = 'fas fa-image';
            break;
        }

        //document
        case 'application/rtf': {
            fileTypeIcon = <i className="far fa-alt" />;
            break;
        }
        // pdf
        case 'application/pdf': {
            fileTypeIcon = <i className="far fa-pdf" />;
            break;
        }

        //zip
        case 'application/vnd.rar':
        case 'application/x-zip-compressed':
        case 'application/x-7z-compressed':
        case 'application/x-bzip':
        case 'application/x-bzip2':
        case 'application/zip': {
            fileTypeIcon = <i className="far fa-file-archive" />;
            break;
        }

        //Orthr file
        default: {
            // fileTypeIcon = <i className="far fa-file" />;
            break;
        }
    }

    return (
        <div className="product-image-thumb">
            {isUploading && (
                <>
                    <a className="btn btn-app">
                        <i className={fontAsomeFileTypeIcon2} /> {filename}
                    </a>
                    <Progress percentage={perProgress} />
                </>
            )}
            {!isNull(error) && (
                <>
                    <a className="btn btn-app">
                        <i className={fontAsomeFileTypeIcon2} /> {filename}
                    </a>
                    <span className="mailbox-attachment-size clearfix mt-1">{error.message}</span>
                </>
            )}
            {!isNull(resData) && (
                <>
                    <a className="btn btn-app">
                        <i className={fontAsomeFileTypeIcon2}></i> {resData.fil_originname}
                    </a>
                    {/* <span className="mailbox-attachment-size clearfix mt-2">
                            <span>{humanFileSize(resData.fil_size)}</span> */}

                    {/* <button
                                type="button"
                                className="btn btn-default btn-sm float-right"
                                onClick={(e) => onRemove(e, uid)}
                            > */}
                    {/* <i className="fas fa-times"></i> */}
                    {/* </button> */}
                    {/* <button
                                type="button"
                                className="btn btn-default btn-sm float-right"
                                onClick={(e) => onDownload(e, uid)}
                            >
                                <i className="fas fa-cloud-download-alt"></i>
                            </button> */}
                    {/* </span> */}
                </>
            )}
        </div>
    );
};

export default ItemCard;
