import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';
import useList from '@/hooks/useList.hook';
import { phoneDashFomatter } from '@/helpers/common.helper';

/**
 * Import Common Components
 */
import SearchBox from '@/components/commons/SearchBox';
import ControlBox from '@/components/commons/ControlBox';
import Pagination from '@/components/commons/Pagination_0';

import Contact_Detail from './contact_detail';
/**
 * Import Apollo libs
 */
import { useLazyQuery, useMutation } from '@apollo/client';
import { GETPOSTLIST_QUERY } from '@/querys/board/post/getPostList';
import { GETPOSTONE_QUERY } from '@/querys/board/post/getPostOne';
import { DELETEPOSTMANY_MUTATION } from '@/querys/board/post/deletePostMany';

const Contact: React.FC = (props): JSX.Element => {
    const [
        state,
        {
            getCheckItems,
            setSearch,
            onChoice,
            onResetChoice,
            onChecked,
            onCheckedAll,
            onReset,
            onUpdate,
            onDelete,
            onAdd,
        },
        { pages, movePage },
    ] = useList({});

    const dialogId = 'ModalCenter';
    const dialogModalRef = useRef(null);
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [viewDetial, setViewDetial] = useState(null);
    const [graphql_data, setGraphql_data] = useState({
        variables: {
            skip: pages.pos_current * pages.cnt_print,
            take: pages.cnt_print,
            where: {
                brd_key: 'atcontact',
            },
        },
    });
    /**
     * 목록 가져오기 쿼리
     */
    const [getList, { data }] = useLazyQuery(GETPOSTLIST_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const { list = [], count = 0 } = data?.getPostList?.post;
            onReset(list, count);
        },
        onError: (err) => console.error(err),
    });

    const [getItem] = useLazyQuery(GETPOSTONE_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const obj = JSON.parse(data.getPostOne.post.pst_content);
            obj['pst_idx'] = data.getPostOne.post.pst_idx;
            obj['pst_datetime'] = data.getPostOne.post.pst_datetime;
            setViewDetial(obj);
        },
        onError: (err) => console.error(err),
    });

    const [delItem] = useMutation(DELETEPOSTMANY_MUTATION, {
        onError: (err) => console.error(err),
    });

    /**
     * 초기 1회 실행
     */
    useEffect(() => {
        getList(graphql_data);
    }, []);

    //검색기능
    const handleSearch = async (search) => {
        if (search.word.length > 0) {
            if (!(search.word.length > 1 && search.word.length <= 15)) {
                window.alert('검색어는 2~15자 까지입니다.');
                return;
            }
        }

        await setSearch(search);

        const _graphql_data = graphql_data;
        if (search.word.length > 0) {
            _graphql_data.variables.where['pst_mem_nickname'] = search.word;
        } else {
            delete _graphql_data.variables.where['pst_mem_nickname'];
        }
        _graphql_data.variables.skip = 0 * pages.cnt_print;
        _graphql_data.variables.take = pages.cnt_print;
        setGraphql_data(_graphql_data);
        movePage(0);
        await getList(graphql_data);
    };

    const handlePage = async (currentPage) => {
        await movePage(currentPage);

        const _graphql_data = graphql_data;
        _graphql_data.variables.skip = currentPage * pages.cnt_print;
        _graphql_data.variables.take = pages.cnt_print;
        setGraphql_data(_graphql_data);
        await getList(graphql_data);
    };

    return (
        <>
            {/* 디테일 창 여는 버튼 */}
            <button
                ref={dialogModalRef}
                style={{ display: 'none' }}
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target={'#' + dialogId}
            >
                dialogModaToggle
            </button>
            <Contact_Detail
                id={dialogId}
                data={viewDetial}
                onClose={() => {
                    //setViewDetial(null);
                    onResetChoice();
                }}
            />
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Athenes Contact Us</h3>
                    <div className="card-tools">
                        <SearchBox inSearch={false} onSearch={handleSearch} placeholder="업체명 검색" />
                    </div>
                </div>

                <div className="card-body p-0">
                    <ControlBox
                        onRemove={async () => {
                            if (!confirm(`모든 항목을 삭제하시겠습니까?`)) return;
                            const delList = getCheckItems();
                            const pst_idxs = _.map(delList, (el) => el.data.pst_idx);
                            const res = await delItem({
                                variables: {
                                    where: {
                                        pst_idx: pst_idxs, //el.data.pst_idx,
                                    },
                                },
                            });
                            if (res?.errors) {
                                alert('삭제에 실패했습니다.');
                                return;
                            }
                            //_.forEach(delList, (el, i) => onDelete(null, i));
                            getList(graphql_data);
                        }}
                        onRefresh={() => {
                            getList(graphql_data);
                        }}
                    />
                    <table className="table table-striped table-hover">
                        <colgroup>
                            <col width={'20px'} />
                            <col width={'30px'} />
                            <col width={'12%'} />
                            <col width={'15%'} />
                            <col width={'10%'} />
                            <col width={'16%'} />
                            <col width={'*'} />
                            <col width={'25px'} />
                            <col width={'25px'} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>
                                    <div className="icheck-primary d-inline">
                                        <input
                                            id="checkboxAll_cnt"
                                            type="checkbox"
                                            defaultChecked={checkAll}
                                            //checked={checkAll}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onCheckedAll(!checkAll);
                                                setCheckAll(!checkAll);
                                            }}
                                        />
                                        <label htmlFor={'checkboxAll_cnt'}></label>
                                    </div>
                                </th>
                                <th>#</th>
                                <th>등록일</th>
                                <th>프로젝트</th>
                                <th>유형</th>
                                <th>업체/담당자명</th>
                                <th>이메일/연락처</th>
                                <th>{/* <i className="fas fa-file-download" /> */}</th>
                                <th>
                                    <i className={'fas fa-trash'} />
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {_.map(state.view_list, (el, i) => {
                                const obj = JSON.parse(el.data.pst_content);
                                return (
                                    <tr
                                        key={'contact_' + i}
                                        className={el.active ? 'bg-primary disabled' : ''}
                                        id={i}
                                        onClick={(e) => {
                                            const _data = onChoice(e);
                                            getItem({
                                                variables: {
                                                    where: {
                                                        pst_idx: _data.data.pst_idx,
                                                    },
                                                    option: {
                                                        is_view: true,
                                                    },
                                                },
                                            });
                                            dialogModalRef.current.click();
                                        }}
                                    >
                                        <td>
                                            <div
                                                className="icheck-primary d-inline"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <input
                                                    id={'cntchk_' + i}
                                                    name={i}
                                                    type="checkbox"
                                                    checked={el.checked}
                                                    onChange={onChecked}
                                                />
                                                <label htmlFor={'cntchk_' + i}></label>
                                            </div>
                                        </td>
                                        <td>{el.data.pst_idx}</td>
                                        <td>
                                            {moment(el.data.pst_datetime).format('YY-MM-DD')}
                                            <br />
                                            <small>{moment(el.data.pst_datetime).format('HH:mm')}</small>
                                        </td>

                                        <td>
                                            <small>
                                                {obj.question_type_1 && (
                                                    <span className="badge bg-teal m-1 p-1">
                                                        <small>프로젝트 의뢰</small>
                                                    </span>
                                                )}
                                                {obj.question_type_2 && (
                                                    <span className="badge bg-info m-1 p-1">문의 사항</span>
                                                )}
                                            </small>
                                        </td>
                                        <td>
                                            {obj.project_type_1 && (
                                                <span className="badge bg-indigo m-1 p-1">웹사이트</span>
                                            )}
                                            {obj.project_type_2 && (
                                                <span className="badge bg-lightblue m-1 p-1">웹퍼블리싱</span>
                                            )}
                                            {obj.project_type_3 && (
                                                <span className="badge bg-orange m-1 p-1">앱개발</span>
                                            )}
                                            {obj.project_type_4 && (
                                                <span className="badge bg-olive m-1 p-1">디자인요청</span>
                                            )}
                                            {obj.project_type_5 && (
                                                <span className="badge bg-danger m-1 p-1">유지보수&리뉴얼</span>
                                            )}
                                        </td>
                                        <td>
                                            {/* {obj.company} */}
                                            {el.data.pst_mem_nickname}
                                            <br />
                                            <small>{obj.name}</small>
                                        </td>
                                        <td>
                                            {obj.email}
                                            <br />
                                            <small>{phoneDashFomatter(obj.phone)}</small>
                                        </td>
                                        <td>
                                            {/* <button
                                            id={i}
                                            type="button"
                                            className="btn btn-block bg-gradient-danger btn-xs"
                                        >
                                            <i className={'fas fa-trash m-1'} />
                                        </button> */}
                                        </td>
                                        <td>
                                            <button
                                                id={i}
                                                type="button"
                                                className="btn btn-block bg-gradient-danger btn-xs"
                                                onClick={async (e) => {
                                                    e.stopPropagation();
                                                    if (!confirm(`${el.data.pst_idx}번 항목을 삭제하시겠습니까?`))
                                                        return;

                                                    const res = await delItem({
                                                        variables: {
                                                            where: {
                                                                pst_idx: el.data.pst_idx,
                                                            },
                                                        },
                                                    });
                                                    if (res?.errors) {
                                                        alert('삭제에 실패했습니다.');
                                                        return;
                                                    }
                                                    if (res?.data?.deletePostMany?.pst_idx[0] === el.data.pst_idx) {
                                                        // //console.log(
                                                        //     res?.data?.deletePostMany?.pst_idx[0] === el.data.pst_idx,
                                                        // );
                                                        //onDelete(e, el.id);
                                                    }
                                                    getList(graphql_data);
                                                }}
                                            >
                                                <i className={'fas fa-trash m-1'} />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination className="m-3" pages={pages} onMovePage={(pageNumber) => handlePage(pageNumber)} />
            </div>
        </>
    );
};

export default Contact;
