import { gql } from '@apollo/client';

export const SETTAB_MUTATION = gql`
    mutation setTabMutation($data: SetTabDataDto!, $where: SetTabWhereDto!) {
        setTab(data: $data, where: $where) {
            at_tab_idx
            at_tab_name
            at_tab_page
        }
    }
`;
