import { Helmet } from 'react-helmet-async';
import { Tab, TabSections } from '@/components/commons/Tab';

import Portfolio from './contents/portfolio';
import Diary from './contents/diary';
import Notice from './contents/notice';
import Activity from './contents/activity';
import Contact from './contents/contact';

const Websiteconfig: React.FC = (): JSX.Element => {
    const asPath = window.location.pathname;
    const asPathArr = asPath.split('/');
    const type = asPathArr[asPathArr.length - 1];
    return (
        <>
            <Helmet>
                <title>RS | {type}</title>
            </Helmet>

            <TabSections
                options={{
                    type: 'config',
                    title: 'WebSite 설정 페이지',
                    path: `${asPath}`,
                    pathname: '/website',
                    header: '',
                }}
            >
                <Tab name="Portfolio" type="portfolio" active={type}>
                    <Portfolio />
                </Tab>
                <Tab name="Diary" type="diary" active={type}>
                    <Diary />
                </Tab>
                <Tab name="Notice" type="notice" active={type}>
                    <Notice />
                </Tab>
                <Tab name="Activity" type="activity" active={type}>
                    <Activity />
                </Tab>
                <Tab name="Contact" type="contact" active={type}>
                    <Contact />
                </Tab>

                {/* <Tab name="API정보" type="apiinfo" active={type}>
                            <ApiInfo
                                onSubmit={handleSubmit}
                                loading={false}
                                data={data.getConfig}
                            />
                        </Tab> */}
            </TabSections>
        </>
    );
};

export default Websiteconfig;
